import "./AppWrapper.scss";
import muiTheme from '../../styles/muiTheme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";

/**
 * Type for props of AppWrapper component.
 */
type AppWrapperProps = {
  themeOverrides?: any;
  children?: React.ReactNode;
  fullWindowHeight?: boolean;
  appId?: string;
};

/**
 * Component that wraps a microapp at the root level to apply styling.
 *
 * @param {React.ReactNode} children - Any children to render.
 */
const AppWrapper = ({
  themeOverrides = {},
  children,
  fullWindowHeight = true,
}: AppWrapperProps) => {

  const style = {
    minHeight: `${fullWindowHeight ? "calc(100vh - var(--header-height))" : ""}`,
  }

  return (
    <div className="aiops-app-wrap" style={style}>
      <ThemeProvider theme={muiTheme(themeOverrides)}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </div>
  );
};

export default AppWrapper;
