import back from '../../assets/icons/arrowLeft.svg';
import next from '../../assets/icons/arrowRight.svg';
import dropdown from '../../assets/icons/arrowDown.svg';
import { useState } from 'react';
import CollapsibleMenu from '../CollapsibleMenu';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import styles from './TableFooterPagination.module.scss';

/**
 * Type for props of TableFooterPagination component.
 * 
 * @param currentPage
 * The user's current page. Must be zero-indexed.
 * 
 * @param rowsPerPage
 * Number of rows the user sees per page.
 * 
 * @param rowsPerPageOptions
 * Array of numbers where each is an option the user can select for how many
 * rows to see per page.
 * 
 * @param totalResults
 * The total number of rows/results.
 * 
 * @param onNextPage
 * Function to run when the user clicks the next page button. No arguments will
 * be provided to it. The function will not be run if the user clicks the next
 * page button on the last possible page.
 * 
 * @param onPreviousPage
 * Function to run when the user clicks the previous page button. No arguments 
 * will be provided to it. The function will not be run if the user clicks the 
 * previous page button on the first possible page.
 * 
 * @param onSelectRowsPerPage
 * Function to run when the user selects one of the options of number of results
 * to see per page.
 * 
 * REMEMBER that one of the things this function should probably do is kick the
 * user back to the first page of results (although this behavior is not
 * enforced by the component and you're allowed to do whatever you want).
 * 
 */
export type TableFooterPaginationProps = {
  currentPage: number,
  rowsPerPage: number,
  rowsPerPageOptions: number[],
  totalResults: number,
  onNextPage: () => unknown,
  onPreviousPage: () => unknown,
  onSelectRowsPerPage: (num: number) => void,
};

/**
 * Renders a footer component that handles table pagination.
 * 
 * Displays how many items per page the user will see. Allows the user to select
 * a different number of results per page from a list of options. Displays
 * "X - Y of Z" where X is the first result on the page, Y is the last, and Z is
 * the total number of results.
 * 
 * The component assumes that your pages are zero-indexed. For a table with 75
 * total results, showing 25 results per page, on page 0 the user would see:
 * "1-25 of 75".
 */
const TableFooterPagination = ({
  currentPage,
  rowsPerPage,
  rowsPerPageOptions,
  totalResults,
  onNextPage,
  onPreviousPage,
  onSelectRowsPerPage,
}: TableFooterPaginationProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);


  // Adjust for currentPage being zero-indexed
  const rangeStart = (currentPage * rowsPerPage) + 1;
  const rangeEnd = Math.min(rangeStart + rowsPerPage - 1, totalResults);

  // True when displaying the first page of results (ie: no previous button)
  const firstPage = currentPage === 0;

  // True when displaying the last page of results (ie: no next button)
  const lastPage = rangeEnd === totalResults;

  const button = (func, imgSrc: string, alt: string, enabled = true) => {
    return (
      <IconButton
        data-testid={alt}
        onClick={enabled ? func : () => null}
        disabled={!enabled}
        disableRipple
      >
        <img src={imgSrc} alt={alt} />
      </IconButton>
    )
  }

  const pageNavButtons = () => {
    return (
      <div className={`row aiops-table-pagination-nav-button-wrap ${styles["footer-nav-button-wrap"]}`}>
        {button(onPreviousPage, back, "previous", !firstPage)}
        {button(onNextPage, next, "next", !lastPage)}
      </div>
    )
  }

  const itemsPerPage = () => {
    return (
      <div className={`row aiops-table-pagination-items-per-page-container ${styles["items-per-page-container"]}`}>
        <Typography variant='caption1c'>Items per page:</Typography>
        <Button
          onClick={handleClick}
          data-testid="items-per-page"
          disableRipple
        >
          {rowsPerPage}
          <img
            src={dropdown}
            className={anchorEl ? "dropdownOpen" : null}
          />
        </Button>
        <ResultsNumberPicker
          anchorEl={anchorEl}
          handleClose={handleClose}
          nums={rowsPerPageOptions}
          onSelectRowsPerPage={onSelectRowsPerPage}
        />
      </div>
    )
  }

  return (
    <div className={`${styles["aiops-table-footer-pagination"]} aiops-table-footer-pagination`}>
      {itemsPerPage()}
      <Typography variant='caption1c'>{`${rangeStart} - ${rangeEnd} of ${totalResults}`}</Typography>
      {pageNavButtons()}
    </div>
  );
};

const ResultsNumberPicker = ({ anchorEl, handleClose, nums, onSelectRowsPerPage }) => {

  const content = () => {
    return (
      <div className={`col aiops-table-pagination-items-per-page-item-container ${styles["items-per-page-item-container"]}`}>
        {nums.map((num, idx) => (
          <Button
            data-testid={`rows-per-page-${idx}`}
            key={idx}
            className={styles.itemsPerPageItem}
            onClick={() => {
              onSelectRowsPerPage(num);
              handleClose();
            }}
            disableRipple
          >
            {num}
          </Button>
        ))}
      </div>
    )
  }

  return (
    <CollapsibleMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      MenuContent={content()}
      width={60}
      customMenuStyles={{
        backgroundColor: 'var(--table-pagination-menu-background)', 
        maxHeight: '152px', 
        overflow: 'auto'
      }}
    />
  )
}

export default TableFooterPagination;
