import { CircularProgress, Typography } from "@mui/material";
import searchIcon from '../../assets/icons/searchIcon.svg';
import styles from '../Table/Table.module.scss';

/**
 * Type for props of TableEmpty component.
 * 
 * @param loading
 * When true the component will render a loading spinner. When false the
 * component will assume the table is empty because there are no rows and will
 * render a message that the table has no contents.
 * 
 * @param emptyTableMessage
 * Custom message to display to user when table is empty and not loading.
 */
export type TableEmptyProps = {
  loading: boolean,
  emptyTableMessage?: string,
};

/**
 * Renders feedback to the user when the table is empty.
 * 
 * When loading is true, the component will render a loading spinner.
 * 
 * When loading is false, the component will assume that the table is empty 
 * because there are no contents and render a message that no results could be
 * found given the search/filter criteria.
 */
const TableEmpty = ({ loading, emptyTableMessage = undefined }: TableEmptyProps) => {

  const DEFAULT_EMPTY_TABLE_MESSAGE = "Please adjust your filter and search criteria."

  const noResults = () => (
    <div className="col">
      <img
        src={searchIcon}
        alt="search icon"
      />
      <Typography
        variant="heading4-light"
      >
        No results found
      </Typography>
      <Typography
        variant="paragraph1"
      >
        {emptyTableMessage || DEFAULT_EMPTY_TABLE_MESSAGE}
      </Typography>
    </div>
  )

  return (
    <div className={`col aiops-table-empty ${styles["aiops-table-empty"]}`}>
      {loading
        ? <CircularProgress />
        : noResults()
      }
    </div>
  );
};

export default TableEmpty;
