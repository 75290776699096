import collapseSvg from "../../assets/icons/collapse.svg";
import "./Collapse.scss";

/**
 * Component that returns an image of a collapse icon
 * @returns an svg image of a collapse icon
 */
const Collapse = () => {

  return (
    <img src={collapseSvg} alt="collapse" />
  );
}

export default Collapse;
