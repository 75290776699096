import { Controller } from 'react-hook-form';
import StyledSwitch from '../StyledSwitch';
import { SwitchProps, Typography } from '@mui/material';


/**
 * Type for props of FormSwitchProps component.
  label: optional label displayed to the right of the switch
  control: The control object is exposed by the useForm hook and is passed to all form Controllers
  controlLabel: The controlLabel is a string that connects the component to the form state, the string should match the corresponding key in the defaultValues object and form props interface. 
 */
type FormSwitchProps = {
  controlLabel: string;
  label?: string;
  control: any;
  switchProps?: SwitchProps
};

export const FormSwitch = ({ controlLabel, label, control, switchProps = {} }: FormSwitchProps) => {
  return (
    <Controller
      key={controlLabel}
      name={controlLabel}
      control={control}
      render={({ field: { value, ...field } }) => (
        <div className='row'>
          <StyledSwitch {...field} checked={!!value} switchProps={switchProps} ref={null} />
          {label && <Typography>{label}</Typography>}
        </div>
      )}
    />
  );
} 