import { ButtonProps, Button } from '@mui/material';
import './LinkButton.scss';

/**
*  Type props for Link Button
*  onClick: function to handle onClick of button
*  buttonContent: text to display or component to render insider of button
*  props: other button props, can be any MUI button props, see MUI documentation for details
 */

type LinkButtonProps = {
  onClick: () => void;
  children: string | JSX.Element;
  buttonProps?: ButtonProps;
}

export const LinkButton = ({ onClick, children, buttonProps = {} }: LinkButtonProps) => {
  return (
    <Button
      disableRipple
      className='payment-link-button'
      {...buttonProps}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}