import { Controller } from 'react-hook-form';
import Checkbox from '../Checkbox';
import { Typography } from '@mui/material';

type FormCheckboxProps = {
  controlLabel: string;
  label: string;
  control: any;
};

const FormCheckbox = ({ controlLabel, label, control }: FormCheckboxProps) => {
  return (
    <Controller
      key={controlLabel}
      name={controlLabel}
      control={control}
      render={({ field: { value, ...field } }) => (
        <div className='row'>
          <Checkbox {...field} checked={!!value} ref={null} />
          <Typography>{label}</Typography>
        </div>
      )}
    />
  );
};

export default FormCheckbox;