import { Typography } from "@mui/material";
import "./Retry.scss";
import retry from "../../assets/icons/retry.svg";

const Retry = (props) => {
  return (
    <div
      className={`retry-wrap ${props?.disabled && "disabled"}`} 
    >
      <img 
        src={retry}
        alt={props?.textToDisplay || "Retry"}
      />
      <Typography fontSize={"small"}>
        {props?.textToDisplay || "Retry"}
      </Typography>
    </div>
  );
};

export default Retry;
