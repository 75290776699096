import TableHeader from "../TableHeader";
import { TableHeaderCellProps } from "../TableHeaderCell";
import TableRow from "../TableRow";
import styles from './Table.module.scss';
import { OptionalTableRowProps } from "../TableRow";
import TableFooterLoadMore, { TableFooterLoadMoreProps } from "../TableFooterLoadMore";
import TableFooterPagination, { TableFooterPaginationProps } from "../TableFooterPagination";
import TableEmpty from "../TableEmpty";

/**
 * Type for props of Table component.
 * 
 * @param columnKeys
 * The keys, in order, that correspond to the key/value pair used to populate 
 * data in each column.
 * 
 * @param rows
 * Array of objects, in order, with any set of key/value pairs. Every
 * key in the columnKeys array should be present in every row object, with a
 * value that will be displayed in the corresponding column.
 * 
 * @param tableIsLoading
 * When true, table will render a TableEmpty component with a loading spinner.
 * 
 * @param emptyTableMessage
 * Optional custom message to display to user in TableEmpty component when Table
 * is empty and tableIsLoading is false.
 * 
 * @param headerCells
 * Optional object where each key is one of the columnKeys and the
 * corresponding value is a TableHeaderCellProps object to be passed as props to 
 * the table header cell for that column. This object can be left undefined to
 * create a table with no header.
 * 
 * @param pagination
 * Optional object to be passed to the TableFooter if the table
 * should have pagination. This object can be left undefined to create a table
 * with no pagination.
 * 
 * @param selectability
 * Optional object if the table's rows should be selectable 
 * with checkboxes. This object can be left undefined to create a table where
 * rows are not seletable.
 * 
 * @param rowProps
 * Set of OptionalTableRowProps to pass to each row.
 * 
 * @param loadMoreProps
 * Set of optional TableFooterLoadMoreProps to pass to TableFooterLoadMore if
 * that component is required.
 * 
 */
export type TableProps = {
  columnKeys: Array<string | number>,
  rows: Array<Record<string, any>>,
  tableIsLoading?: boolean,
  emptyTableMessage?: string,
  headerCells?: Record<string, TableHeaderCellProps>,
  pagination?: TableFooterPaginationProps,
  selectability?: Selectability,
  rowProps?: OptionalTableRowProps,
  loadMoreProps?: TableFooterLoadMoreProps,
};

/**
 * Type that defines how the Table's selectability (for rows and header) will
 * work.
 * 
 * @param rowIsSelected
 * Function that takes the row's row object as input and returns a boolean. If
 * true, the row's checkbox will be checked.
 * 
 * @param rowIsSelectable
 * Either a boolean or a function that takes that row's row object as input and
 * returns a boolean. If true, the row can be selected/deselected.
 * (rowIsSelectable === !disabled, and vice-versa)
 * 
 * @param selectAllCheckbox
 * If true, the table's header will have a select all checkbox.
 * 
 * @param selectAllCheckboxValue
 * The (true or false) value the header's select all checkbox should have.
 * 
 * @param selectAllCheckboxIndeterminate
 * If true, the select all checkbox will have an indeterminate value (ie the
 * horizontal line indicating neither checked nor unchecked)
 * 
 * @param selectAllCheckboxDisabled
 * If true, the header's select all checkbox will be disabled.
 * 
 * @param onSelectAllToggle
 * Function that runs when the header's select all checkbox is clicked. It takes
 * the checkbox's new value as input (ie if the box goes from checked to
 * unchecked, this function receives false as its input).
 * 
 * @param onSelectRowToggle
 * Function that runs when a row's checkbox is clicked. It takes the checkbox's
 * new value and the row's row object as input (ie if the box goes from checked
 * to unchecked this function receives false and the row object as input).
 * 
 */
export type Selectability = {
  rowIsSelected: (row: Record<string, any>) => boolean,
  rowIsSelectable: boolean | ((row: Record<string, any>) => boolean),
  selectAllCheckbox: boolean,
  selectAllCheckboxValue?: boolean,
  selectAllCheckboxIndeterminate?: boolean,
  selectAllCheckboxDisabled?: boolean,
  onSelectAllToggle?: (checked: boolean) => void,
  onSelectRowToggle: (checked: boolean, row: any) => void,
}

/**
 *  Renders a styled table.
 */
const Table = ({
  columnKeys,
  rows,
  tableIsLoading = false,
  emptyTableMessage = undefined,
  headerCells = undefined,
  pagination = undefined,
  selectability = undefined,
  rowProps = undefined,
  loadMoreProps = undefined,
}: TableProps) => {

  return (
    <div className={`${styles["aiops-table-wrap"]} aiops-table-wrap`}>
      {
        tableIsLoading || rows.length === 0
          ? <TableEmpty
            loading={tableIsLoading}
            emptyTableMessage={emptyTableMessage}
          />
          : <>
            <table className={`${styles["aiops-table"]} aiops-table`}>
              {headerCells && <thead>
                <TableHeader
                  headerCells={headerCells}
                  columnKeys={columnKeys}
                  selectability={selectability}
                />
              </thead>}
              <tbody className={`${styles["aiops-table"]} aiops-table`}>
                {
                  rows.map((row, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        columnKeys={columnKeys}
                        row={row}
                        rowIndex={idx}
                        rowProps={rowProps}
                        selectability={selectability}
                      />
                    )
                  })
                }
              </tbody>
            </table>
            {loadMoreProps && <TableFooterLoadMore {...loadMoreProps} />}
            {pagination && <TableFooterPagination {...pagination} />}
          </>
      }
    </div>
  );
};

export default Table;
