import { IconButton } from '@mui/material';
import { ButtonProps } from '@mui/material';
import * as React from 'react';

import './StyledIconButton.scss';

/**
 * Type for props of StyledIconButton component.
 * icon: JSX Element to display inside button
 * disabled: optional prop to diable icon button
 * props: other optional button props, can be any MUI button props, see MUI documentation for details
 */
type StyledIconButtonProps = {
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  icon: JSX.Element;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  isSquare?: boolean;
};

/**
 *
 */
const StyledIconButton = ({ onClick, icon, disabled = false, isSquare = false, buttonProps = {} }: StyledIconButtonProps) => {
  return (
    <IconButton
      disabled={disabled}
      className="styled-icon-button"
      onClick={onClick}
      // makes button square when isSquare is true and round in other cases
      sx={{
        borderRadius: isSquare ? 1 : '50%',
        '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
          borderRadius: isSquare ? 1 : '50%',
        }
      }}
      {...buttonProps}
    >
      {icon}
    </IconButton>
  );
};

export default StyledIconButton;
