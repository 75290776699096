import {
    getIdToken,
} from '@aiops/auth-util';
import { fetchEventSource } from "@microsoft/fetch-event-source"; 

// List of endpoints and their corresponding methods that require custom error messages to be displayed rather than generic error messages.
export const ENDPOINTS_MAP = {
    genaiRegisterUsecase: {
        endpoint: '/usecase/register',
        method: 'POST',
    },
}

/**
 * Returns the error string for a given endpoint and method present in the ENDPOINTS_MAP object.
 * 
 * This mapping method has been added because of inconsistent error message objects being sent by the backend for various APIs.
 * Until such time the effort is taken up to send consistent error messages from the backend, this method will be used to map the error messages to the frontend.
 * 
 * @param url
 * The url to make the request to (including any parameters).
 * @param method 
 * The http method to use ("GET", "POST", "PUT", "PATCH", "DELETE").
 * @param errorObj
 * The error object received from the backend. 
 * 
 * @returns
 * The error string to be displayed to the user.
 */
export const getErrorStringPerEndpoint = (url: string, method: string, errorObj: any, ENDPOINTS_MAP: any) => {
    const genericError = `Unable to make ${method} request to: ${url}`;
    if (errorObj) {
        if (url.includes(ENDPOINTS_MAP?.genaiRegisterUsecase?.endpoint) && method === ENDPOINTS_MAP?.genaiRegisterUsecase?.method) {
            return errorObj?.detail?.response || genericError;
        } else {
            return genericError;
        }
    } else {
        return genericError;
    }
}

/**
 * Makes an authenticated fetch request to the provided url.
 * 
 * @param url 
 * The url to make the request to (including any parameters).
 * 
 * @param method 
 * The http method to use ("GET", "POST", "PUT", "PATCH", "DELETE").
 * 
 * @param body 
 * The body of the request (if any). GET and DELETE requests will throw an
 * error if a body is provided.
 * 
 * @param headers 
 * Any additional headers to include in the request. These will overwrite the
 * defaults.
 * 
 * @returns 
 * The response from a fetch request. Throws an error if the fetch request
 * throws an error, or if its response.ok is falsey, or if it's unable to get
 * the user's id token.
 */
export const authenticatedFetchRequest = async (
    url: string,
    method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE',
    body: null | Record<string, any> = null,
    headers: null | Record<string, any> = null,
) => {
    const idToken = getIdToken();
    if (!idToken) {
        throw new Error(`Could not get id token from auth-util: ${idToken}`);
    }
    if (!url) {
        throw new Error(`Cannot make request without url: ("${url}")`);
    }
    if ((method === "GET" || method === "DELETE") && body) {
        throw new Error(`Cannot make ${method} request with body: ("${body}")`);
    }
    const req = {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getIdToken()}`,
            ...headers,
        },
        body: body ? JSON.stringify(body) : undefined,
    }
    return await fetch(url, req)
        .then(async (res) => {
            if (!res.ok) {
                const errorObj = await res.json();
                console.error(`Bad res: ${res.status} - ${res.statusText}`);
                const errorStr = getErrorStringPerEndpoint(url, method, errorObj, ENDPOINTS_MAP);
                throw new Error(errorStr);
            }
            else {
                return res.json()
            }
        });
}

/**
 * Makes an authenticated fetch event source request to the provided url.
 * @param options
 * Object containing the following properties:
 * @param url
 * The url to make the request to.
 * 
 * @param method
 * The http method to use ("POST").
 * 
 * @param body
 * The body of the request (if any). 
 * 
 * @param headers
 * Any additional headers to include in the request. These will overwrite the
 * defaults.
 * 
 * @param onopen
 * Function to call when the connection is opened.
 * 
 * @param onmessage
 * Function to call when a message is received.
 * 
 * @param onclose
 * Function to call when the connection is closed.
 * 
 * @param onerror
 * Function to call when an error occurs.
 * 
 * @param signal
 * The AbortSignal to use for the request.
 */
export const authenticatedFetchEventSourceRequest = async (options: {
    url: string,
    method: 'POST',
    body: Record<string, any>,
    headers: Record<string, any>,
    onopen: (response: any) => Promise<void>, 
    onmessage: (message: any) => void, 
    onclose: () => void, 
    onerror: (error: any) => void,
    signal: AbortSignal,
}) => {
    const idToken = getIdToken();
    const { url, method, headers, body, signal, onopen, onmessage, onclose, onerror } = options;
    
    if (!idToken) {
        throw new Error(`Could not get id token from auth-util: ${idToken}`);
    }
    if (!url) {
        throw new Error(`Cannot make request without url: ("${url}")`);
    }
    const request = {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getIdToken()}`,
            ...headers,
        },
        body: body ? JSON.stringify(body) : undefined,
    }
    return await fetchEventSource(url, {
        ...request,
        openWhenHidden: true,
        signal,
        onopen,
        onmessage,
        onclose,
        onerror,
    });
}