import React from 'react';
import styles from "./FilterBar.module.scss";
import Typography from "@mui/material/Typography";
import resetSvg from '../../assets/icons/resetAllIcon.svg';
import { ReactSVG } from 'react-svg';

/**
 * Type for props of FilterButton component.
 * 
 * @param id
 * Unique identifier for this filter button.
 * 
 * @param text
 * Text to display on the button.
 * 
 * @param children
 * Children to display on the button.
 * 
 * @param selected
 * Whether or not this button is selected (boolean).
 * 
 * @param disabled
 * Whether or not this button is disabled (boolean).
 * 
 * @param style
 * Optional inline style object to apply to the button.
 * 
 * @param classNames
 * Optional string or array of strings of classNames to apply to the button.
 * 
 */
export type FilterButtonProps = {
  id: string,
  text?: string,
  count?: number,
  selected: boolean,
  disabled?: boolean,
  children?: JSX.Element | JSX.Element[],
  style?: React.CSSProperties,
  classNames?: string | string[],
}

/**
 * Type for props of FilterBar component.
 * 
 * @param filterButtons
 * Array of FilterButtonProps objects to render as buttons.
 * 
 * @param onChange
 * Callback that fires when a filter button is clicked. Passes the new state
 * of the filter buttons as an array of FilterButtonProps objects.
 * 
 * @param title
 * Optional string or array of strings to display as the title of the filter
 * bar. Defaults to "Filter by:".
 */
export type FilterBarProps = {
  filterButtons: FilterButtonProps[],
  onChange: (newState: FilterButtonProps[]) => void,
  title?: string | string[];
};

/**
 *  Renders a bar filled with filter buttons.
 */
const FilterBar = ({ filterButtons, onChange, title = "Filter by:" }: FilterBarProps) => {

  const filterButton = (props: FilterButtonProps, idx: number, arr: FilterButtonProps[]) => {
    // The button should have no border radius on its left side if both it and
    // the button to its left are selected. Vice versa for right side. These
    // classes only apply styles if the 'selected' class is also present (see
    // the scss file).
    const squareLeft = idx !== 0 && arr[idx - 1].selected;
    const squareRight = idx < arr.length - 1 && arr[idx + 1].selected;

    // flatten in case classNames is an array
    const classNames = [props.classNames, props.disabled ? "disabled" : '', 'filter-button', 'clickable']
      .filter((nonEmpty) => nonEmpty)
      .flat();
    if (props.selected) {
      classNames.push('selected');
    }
    if (squareLeft) {
      classNames.push('square-left');
    }
    if (squareRight) {
      classNames.push('square-right');
    }

    return (
      <button
        className={classNames.join(' ')}
        onClick={() => handleButtonClick(props.id)}
        disabled={props.disabled}
        key={props.id}
        style={props.style || {}}
      >
        {props.text &&
          <Typography variant="caption1">
            {props.text}{props.count !== undefined ? ` (${props.count})` : ''}
          </Typography>
        }
        {props.children}
      </button>
    )
  }

  // Creates a new array of filter buttons with the button with the given id
  // toggled and calls the onChange callback with the new array.
  const handleButtonClick = (id: string) => {
    // Create deep copy of filter button objects. Spreading the array doesn't
    // create new objects, so map to spread objects.
    const newFilters = filterButtons.map((f) => ({ ...f }));
    const thisFilter = newFilters.find((f) => f.id === id);
    if (thisFilter) {
      thisFilter.selected = !thisFilter.selected;
    }
    onChange(newFilters);
  }

  // Renders a reset button that deselects all filters.
  // The button is selected if all the filter buttons are unselected, and
  // disabled if all the buttons are disabled.
  const resetButton = () => {
    const anySelected = filterButtons.some((f) => f.selected);
    const allDisabled = filterButtons.every((f) => f.disabled);
    return (
      <button
        className={`clickable reset-button ${anySelected ? '' : 'selected'} ${allDisabled ? 'disabled' : ''}`}
        data-testid="reset-button"
        onClick={() => {
          const newFilters = [...filterButtons];
          newFilters.forEach((f) => f.selected = false);
          onChange(newFilters);
        }}
        disabled={allDisabled}
      >
        <ReactSVG
          beforeInjection={(svg) => {
            svg.classList.add('reset-button-icon')
          }}
          src={resetSvg}
        />
      </button>
    )
  }

  return (
    <div className={`row ${styles.filterBar}`}>
      <div className={`filter-by ${filterButtons.every((f => f.disabled)) ? "disabled" : ""}`}>
        {<Typography variant="paragraph1">
          {title}
        </Typography>}
      </div>
      {filterButtons.map((f, idx, arr) => filterButton(f, idx, arr))}
      {resetButton()}
    </div >
  );
};

export default FilterBar;
