import { MenuItem, Select, SelectProps, Typography } from "@mui/material";
import ChevronDown from '../../assets/icons/arrowDown.svg';
import { ReactNode } from "react";
import styles from './StyledDropdown.module.scss';
import { ReactSVG } from "react-svg";

/**
 * StyledDropdown component
 * @param {string} size - size of the dropdown (small, medium)
 * @param {string} label - label for the dropdown
 * @param {string} displayName - display name for the dropdown
 * @param {any} selectedValue - [required] selected value for the dropdown
 * @param {function} onChange - [required] function to handle change event
 * @param {function} renderValue - function to render the selected value in the dropdown
 * @param {any[]} options - [required] array of options for the dropdown
 * @param {function} renderMenuItem - function to render the custom menu item
 * @param {any} iconComponent - custom icon component for the dropdown
 * @param {boolean} disabled - flag to disable the dropdown
 * @param {boolean} open - flag to open the dropdown
 */

export type StyledDropdownProps = {
    size?: 'small' | 'medium',
    label?: string,
    displayName?: string,
    selectedValue: any,
    onChange: (e: any) => void,
    renderValue?: (selectedValue: any) => ReactNode,
    options: any[],
    renderMenuItem?: (menuItem: any) => void,
    iconComponent?: any,
    disabled?: boolean,
    open?: boolean,
}

const DropdownIcon = (props: SelectProps) => {
    const isOpen = props.className?.indexOf('MuiSelect-iconOpen') !== -1;
    return (
        <ReactSVG src={ChevronDown} className={`${styles.styledDropdownIcon} ${isOpen ? 'open-dropdown' : 'close-dropdown'}`} />
    );
}

const StyledDropdown = ({
        /* default size is medium corresponds to large in styleguide */
        size = 'medium',
        label,
        displayName,
        selectedValue,
        onChange,
        renderValue,
        options,
        renderMenuItem,
        iconComponent,
        disabled = false,
        open
    } : StyledDropdownProps) => {
    return (
        <div className={styles.styledDropdown}>
          { displayName && <Typography variant='paragraph1-bold' className='label'>{displayName}</Typography> }
          <Select
              open={open}
              disabled={disabled}
              size={size}
              label={label}
              value={selectedValue}
              onChange={onChange}
              renderValue={renderValue}
              IconComponent={iconComponent || DropdownIcon}
              notched={false}
              MenuProps={{
                classes: {
                    paper: `${styles.styledDropdownMenu}`
                }
              }}
          >
            {options.map((menuItem: any) => {
              if(renderMenuItem) {
                return renderMenuItem(menuItem)
              }
              return <MenuItem value={menuItem} key={menuItem}>{menuItem}</MenuItem>
            })
            }
          </Select>
        </div>
    );
}

export default StyledDropdown;