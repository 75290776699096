import { Button } from '@mui/material';
import './Form.scss'

type FormFooterProps = {
  confirmText?: string;
  exitText?: string;
  confirmDisabled?: boolean;
  confirmOnClick?: () => void;
  exitOnClick?: () => void;
};

export const FormFooterContent = ({ confirmText, exitText, confirmDisabled = false, confirmOnClick, exitOnClick }: FormFooterProps) => {
  return (
    <div className='footer-content'>
      {exitText && <Button variant='outlined' onClick={exitOnClick}>{exitText}</Button>}
      {confirmText && <Button variant='filled' onClick={confirmOnClick} disabled={confirmDisabled}>{confirmText}</Button>}
    </div>
  )
};