import { Controller } from 'react-hook-form';
import './FormRadioButtonGroup.scss';
import { RadioGroupWrapper } from './RadioGroupWrapper';

/**
 * FormRadioButtonGroup component
 * @param groupData - Array of objects containing value and label for each radio button
 * @param controlLabel - Label for the radio button group
 * @param control - control object from react-hook-form
 * @param onChangeCallback - Callback function to be called when a radio button is selected.
 */

type RadioButtonGroupProps = {
  groupData: {
    value: string;
    label: string;
  }[];
  controlLabel: string;
  control: any;
  onChangeCallback?: (value: string) => void;
  isDisabled?: boolean;
};


export const FormRadioButtonGroup = ({ groupData, controlLabel, control, onChangeCallback, isDisabled=false }: RadioButtonGroupProps) => {

  const onValueChange = (onChange: any, value: string) => {
    if(onChangeCallback) {
      onChangeCallback(value);
    }
    return onChange(value);
  }

  return (
    <Controller
      key={controlLabel}
      name={controlLabel}
      control={control}
      render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
        <RadioGroupWrapper value={value} onChange={changedValue => onValueChange(onChange, changedValue)} field={field} groupData={groupData} error={error} isDisabled={isDisabled} />
      )}
    />
  );
};
