import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import IconDatePicker from "../../assets/icons/icon-datepicker.svg";
import { Controller } from "react-hook-form";
import styles from './FormDatePicker.module.scss';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const IconDatePickerElement = () => <img src={IconDatePicker} alt="open-calendar" />;

export type FormDatePickerProps = {
  control: any;
  controlLabel: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  onChange?: (date: Date | null) => void;
  validation?: {
    minDate?: any,
    shouldDisableDate?: (date: Date) => boolean
  };
  allowTextInput: boolean;
}


export const FormDatePicker = ({ control, controlLabel, label, disabled, onChange, validation, allowTextInput = true }: FormDatePickerProps) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={styles.datePickerControlled}>
        <Controller
          name={controlLabel}
          key={controlLabel}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              label={label}
              disabled={disabled}
              slots={{
                openPickerIcon: IconDatePickerElement
              }}
              slotProps={{
                textField: {
                  helperText: error?.message,
                  error: !!error,
                },
                field: {
                  readOnly: !allowTextInput,
                },
                popper: {
                  className: styles.datePickerPopper,
                }
              }}
              value={field.value ? new Date(field.value) : null}
              onChange={(date: Date | null): void => {
                field.onChange(date);
                if (onChange) {
                  onChange(date);
                }
              }}
              minDate={validation?.minDate}
              shouldDisableDate={validation?.shouldDisableDate}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
