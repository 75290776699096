import StyledTextfield from '../Textfield';
import { Controller } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

/**
 * Type for props of Textfield component.
 */
type StyledTextfieldProps = {
  label?: string;
  controlLabel: string;
  placeholder: string;
  width?: number | string;
  size?: 'small' | 'medium';
  control: any;
  textfieldProps?: TextFieldProps;
};

const FormTextfield = ({ label, width = 250, size = 'small', placeholder, control, controlLabel, textfieldProps = {} }: StyledTextfieldProps) => {
  return (
    <Controller
      key={controlLabel}
      name={controlLabel}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextfield field={field} width={width} displayName={label} placeholder={placeholder} size={size} textfieldProps={textfieldProps} error={error} />
      )}
    />
  )
};

export default FormTextfield;