import { Card, CardProps } from '@mui/material';
import './Form.scss';

type FormCardProps = {
  children: JSX.Element;
  cardProps?: CardProps;
}

const FormCard = ({ children, cardProps = {} }: FormCardProps) => {
  return (
    <Card className='formCard' {...cardProps}>
      {children}
    </Card>
  );
};

export default FormCard;