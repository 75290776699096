import { AppBar, AppBarProps } from '@mui/material';
import './Footer.scss'

// Footer props
// children: footer content to render
// footerProps: MUI AppBar props
type FooterProps = {
  children: JSX.Element;
  footerProps?: AppBarProps;
};

export const Footer = ({ children, footerProps = {} }: FooterProps) => {
  return (
    <AppBar className='footer' {...footerProps}>
      {children}
    </AppBar>
  )
};