import { TextField, TextFieldProps } from '@mui/material';
import { Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import './StyledTextfield.scss'

/**
 * Type for props of Textfield component.
 */
type StyledTextfieldProps = {
  placeholder: string;
  width?: number | string;
  size?: 'small' | 'medium';
  displayName?: string,
  field: any,
  textfieldProps?: TextFieldProps
  error?: FieldError;
};

const StyledTextfield = ({ width = 250, size = 'small', placeholder, displayName, field = {}, textfieldProps = {}, error }: StyledTextfieldProps) => {
  return (
    <div className='group'>
      {displayName && <Typography variant='paragraph1-bold' className='label'>{displayName}</Typography>}
      <TextField
        {...field}
        sx={{ width: width }}
        className='textfield'
        label={placeholder}
        size={size}
        error={!!error}
        helperText={error?.message}
        autoComplete='off'
        {...textfieldProps}
      />
    </div>
  )
};

export default StyledTextfield;