import styles from '../Table/Table.module.scss';
/**
 * Type for props of TableRowCell component.
 * 
 * @param data
 * What to render in the cell. Technically optional.
 * 
 * This will be rendered directly with zero checks, so it must be renderable or
 * you may get unexpected results.
 * 
 * Strings, numbers, JSX elements, React components will all render normally.
 * Objects will throw an error. React will try to concatenate arrays into a
 * string (and will throw an error if an element in the array is an object).
 * 
 * Try to render whatever you want. With great power comes great responsibility.
 * 
 * @param style
 * Optional inline style object.
 * 
 * @param classNames
 * Optional string that is a list of whitespace-separated class names.
 * 
 * @param onClick
 * Optional function to run when the cell is clicked. Will receive the row's
 * object as its first argument and the row's index as its second argument.
 */
type TableRowCellProps = {
  data?: any,
  style?: Record<string, React.CSSProperties>,
  classNames?: string,
  onClick?: (arg: any) => any,
};

/**
 *  Renders a cell within a table row.
 */
const TableRowCell = ({ data, style, classNames, onClick }: TableRowCellProps) => {

  // Filter to remove empty strings (ie if no classNames are passed)
  const cellClassNames = ['aiops-table-row-cell', classNames].filter((str) => str);
  return (
    <td
      className={`${styles['aiops-table-row-cell']} ${cellClassNames.join(" ")}`}
      style={style}
      onClick={onClick}
    >
      {data}
    </td>
  );
};

export default TableRowCell;
