import { SyntheticEvent, useState } from 'react';
import TabPanelView from './TabPanelView';
import TabSwitcher from './TabSwitcher';
import { ITabViewProps } from './interface';
import tabStyles from './tabView.module.scss';

/**
 * TabView component
 * @param {ITabViewProps} props - TabView props
 * @returns {JSX.Element} TabView component
 */

const TabView = (props: ITabViewProps): JSX.Element => {
  const routeConfig = props.config?.routes;
  const defaultSelected = props.config?.defaultSelected ?? 0;
  const [tabSelected, setTabSelected] = useState(defaultSelected);
  const handleChange = (event: SyntheticEvent, newSelection: number) => {
    setTabSelected(newSelection);
  };

  return (
    <div className={tabStyles.tabsViewer}>
      <TabSwitcher routes={routeConfig} selected={tabSelected} onTabSelectionChange={handleChange} />
      <TabPanelView routes={routeConfig} selected={tabSelected} />
    </div>
  );
};

export default TabView;
