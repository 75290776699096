// Custom hook for getting window size
// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

import { useState, useEffect } from 'react';
import layoutSizes from '../constants/layoutSizes';

function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowSize(
    minAppWidth: number = layoutSizes.MIN_APP_WIDTH,
    maxAppWidth: number = layoutSizes.MAX_APP_WIDTH,
    headerHeight: number = layoutSizes.HEADER_HEIGHT,
) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowSize());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Clamp the screen width between the min and max app width to get the width
    // of the app.
    const appWidth = Math.max(
        minAppWidth,
        Math.min(windowDimensions.width, maxAppWidth)
    );

    // return windowDimensions;
    return {
        screenWidth: windowDimensions.width,
        screenHeight: windowDimensions.height,
        appWidth,
        minAppHeight: windowDimensions.height - headerHeight,
    }
}
