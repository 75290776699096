import { Tabs, Tab, Typography } from '@mui/material';
import { IRoute, ITabProps } from './interface';
import styles from './TabSwitcher.module.scss';

const TabSwitcher = (props: ITabProps) => {
  return (
    <Tabs
      className={styles.tabSwitcher}
      value={props.selected}
      onChange={props.onTabSelectionChange}
      aria-label="tab switcher view"
    >
      {props.routes?.map(({ view, disabled }: IRoute) => (
        <Tab key={view} label={<Typography variant="paragraph1-bold">{view}</Typography>}  disabled={disabled} />
      ))}
    </Tabs>
  );
};

export default TabSwitcher;
