import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import styles from '../Table/Table.module.scss';

/**
 * Type for props of TableFooterLoadMore component.
 * 
 * @param onLoadMore
 * Function that runs when the user clicks the "load more" button.
 * 
 * @param loadMoreIsLoading
 * When true, component will display a loading spinner instead of a button or
 * other text.
 * 
 * @param moreResultsExist
 * When true, component will display a button (when not loading) that allows the
 * user to click to load more results. When false, component will display text
 * that tells the user there are no more results to load.
 * 
 * @param loadMoreButtonText
 * Optional string to display in the button the user can press to load more 
 * results. (Component defaults to "Load More" if this prop isn't supplied.)
 * 
 * @param noMoreResultsText
 * Optional string to display to the user when there are no more results to 
 * display. (Component defaults to "No More Results" if this prop isn't supplied.)
 */
export type TableFooterLoadMoreProps = {
  onLoadMore: () => void,
  loadMoreIsLoading: boolean,
  moreResultsExist: boolean,
  loadMoreButtonText?: string,
  noMoreResultsText?: string,
};

/**
 *
 */
const TableFooterLoadMore = ({
  onLoadMore,
  loadMoreIsLoading,
  moreResultsExist,
  loadMoreButtonText = undefined,
  noMoreResultsText = undefined,
}: TableFooterLoadMoreProps) => {
  return (
    <div className={`row aiops-table-footer-load-more ${styles["aiops-table-footer-load-more"]}`}>
      {loadMoreIsLoading && <CircularProgress />}
      {!loadMoreIsLoading && !moreResultsExist &&
        <h5>
          {noMoreResultsText || "No More Results"}
        </h5>
      }
      {!loadMoreIsLoading && moreResultsExist &&
        <Button
          onClick={onLoadMore}
          variant={'outlined'}
        >
          {loadMoreButtonText || "Load More"}
        </Button>
      }
    </div>
  );
};

export default TableFooterLoadMore;
