import { IRoute, ITabPanelProps, ITabPanelViewProps } from './interface';

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={'simple-tabpanel'}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const TabPanelView = (props: ITabPanelViewProps) => {
  return (
    <>
      {props.routes?.map(({ view, component, order }: IRoute) => (
        <TabPanel key={order} value={props.selected} index={order}>
          {component}
        </TabPanel>
      ))}
    </>
  );
};

export default TabPanelView;
