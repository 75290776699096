import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "./Copy.scss";
import copyClicked from "../../assets/icons/copyClicked.svg";
import copyUnclicked from "../../assets/icons/copyUnclicked.svg";

/**
 * Component to display a Copy icon
 * @property disabled
 * Boolean to disable the button
 * @property textToDisplay
 * Text to display beside the copy button
 * defaults to "Copy"
 * @property copyClicked
 * Boolean state of the copy button
 * @returns 
 * React Component
 */
const Copy = (props) => {
  const [clicked, setClicked] = useState<boolean>(props?.copyClicked);

  useEffect(() => {
    setClicked(props?.copyClicked);
  }, [props?.copyClicked]);

  const maps = {
      CLICKED: {
          key: "clicked",
          img: copyClicked,
      },
      UNCLICKED: {
        key: "unclicked",
        img: copyUnclicked,
      },
  }
  return (
    <div 
      className={`copy-wrap ${props?.disabled && "disabled"}`}
    >
      <img
        src={clicked ? maps.CLICKED.img : maps.UNCLICKED.img} 
        alt={props?.textToDisplay || "Copy"}
        key={clicked ? maps.CLICKED.key : maps.UNCLICKED.key} 
      />
      <Typography fontSize={"small"}>{props?.textToDisplay || "Copy"}</Typography>
    </div>
  );
}

export default Copy;
