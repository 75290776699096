import { useEffect, useState } from 'react';
import "./Checkbox.scss";
import MuiCheckbox from '@mui/material/Checkbox';
import checked from '../../assets/icons/checkboxChecked.svg';
import onHover from '../../assets/icons/checkboxCheckedOnHover.svg';
import unchecked from '../../assets/icons/checkboxUnchecked.svg';
import indeterminate from '../../assets/icons/checkboxIndeterminate.svg';
import disabledChecked from '../../assets/icons/checkboxDisabledChecked.svg';
import disabledUnchecked from '../../assets/icons/checkboxDisabledUnchecked.svg';
import disabledIndeterminate from '../../assets/icons/checkboxDisabledIndeterminate.svg';

/**
 *  Returns a MUI Checkbox with default AIOPS Styling.
 */
const Checkbox = (props: any) => {
  // The maps object, iconMap state, and useEffect exist to avoid nested ternary
  // statements, or repeated logic, for figuring out which icon to use for each
  // possible state the checkbox might be in.
  const maps = {
    DISABLED: {
      checked: disabledChecked,
      unchecked: disabledUnchecked,
      indeterminate: disabledIndeterminate,
    },
    ON_HOVER: {
      checked,
      unchecked: onHover,
      indeterminate,
    },
    DEFAULT: {
      checked,
      unchecked,
      indeterminate,
    },
  }

  const disabled: boolean = props.disabled || false;
  const [mouseHovering, setMouseHovering] = useState(false);
  const [iconMap, setIconMap] = useState(maps.DEFAULT);

  useEffect(() => {
    if (disabled) {
      setIconMap(maps.DISABLED);
    } else if (mouseHovering) {
      setIconMap(maps.ON_HOVER);
    } else {
      setIconMap(maps.DEFAULT);
    }
  }, [disabled, mouseHovering]);

  const propsWithDefaults = {
    checkedIcon: <img key="checkbox-icon-checked" src={iconMap.checked} />,
    icon: <img key="checkbox-icon-unchecked" src={iconMap.unchecked} />,
    indeterminateIcon: <img key="checkbox-icon-indeterminate" src={iconMap.indeterminate} />,
    onMouseEnter: () => setMouseHovering(true),
    onMouseLeave: () => setMouseHovering(false),
    ...props,
  }

  return (
    <MuiCheckbox {...propsWithDefaults} />
  );
};

export default Checkbox;
