import * as React from "react";
import "./Toast.scss";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ToastMsg } from '../../contexts/ToastContext';
import successIcon from '../../assets/icons/toastSuccessCheck.svg';
import errorIcon from '../../assets/icons/toastErrorX.svg';
import closeIcon from '../../assets/icons/toastCloseX.svg';
import IconButton from "@mui/material/IconButton";

/**
 * Minimum possible duration that a Toast is on screen in milliseconds.
 * 
 * 2000 is arbitrary, but long enough to at least see it on screen.
 */
export const MIN_TOAST_AUTO_HIDE_DURATION = 2000;

/**
 * Type for props of Toast component (extends ToastMsg).
 * 
 * @param open
 * When true, Toast is visible on screen.
 * 
 * @param closeAllToasts
 * Function that closes all Toasts on screen.
 * 
 * @param closeThisToast
 * Function that closes only this toast.
 * 
 * @param index
 * Index of this toast in the array of toasts. 0 by default.
 * 
 */
export type ToastProps = ToastMsg & {
  open: boolean,
  closeAllToasts: () => void,
  closeThisToast: (event: any) => void,
  index?: number,
};

/**
 * Renders a Toast (MUI snackbar).
 */
const Toast = ({
  open,
  severity,
  message,
  closeAllToasts,
  closeThisToast,
  autoHideDuration = undefined,
  index = 0,
  children = undefined,
}: ToastProps) => {

  const error = severity === 'error';

  // Size of success or error icon
  const ICON_SIZE = '32px';

  // Success or error icon
  const icon = <img
    src={error ? errorIcon : successIcon}
    alt={error ? 'Error Icon' : 'Success Icon'}
    style={{
      height: ICON_SIZE,
      width: ICON_SIZE,
    }}
  />

  // Button on the right side of the toast that closes it
  const closeButton = (
    <IconButton
      onClick={closeThisToast}
    >
      <img src={closeIcon} />
    </IconButton>
  )

  /**
   * Returns the autoHideDuration number of milliseconds if it meets or exceeds
   * the minimum, or the minimum if it's a number that's too small; otherwise
   * null.
   */
  const timeOnScreen = () => {
    if (typeof autoHideDuration === 'number') {
      if (autoHideDuration < MIN_TOAST_AUTO_HIDE_DURATION) {
        console.error(`Replacing received auto hide duration value of ${autoHideDuration} milliseconds with minimum value of ${MIN_TOAST_AUTO_HIDE_DURATION} milliseconds.`);
        return MIN_TOAST_AUTO_HIDE_DURATION;
      }
      return autoHideDuration;
    }
    return null;
  }

  // How tall each toast is.
  const HEIGHT_IN_PIXELS = 53;

  // Margin between toasts.
  const MULTI_TOAST_MARGIN = 3;

  const toastStyles = {
    position: 'absolute',
    // This toast's distance from the top of the app (not the top of the screen)
    top: `${MULTI_TOAST_MARGIN + ((index + 1) * (HEIGHT_IN_PIXELS + MULTI_TOAST_MARGIN))}px`,
    height: `${HEIGHT_IN_PIXELS}px`,
    borderLeft: `8px solid ${error ? "var(--danger)" : "var(--good-job)"}`,
    background: "white",
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={timeOnScreen()}
      onClose={closeAllToasts}
      message=""
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        icon={icon}
        severity={severity}
        action={closeButton}
        className="toast-wrap"
        sx={toastStyles}
      >
        <div className="inner-toast">
          <h5>{message}</h5>
          {children}
        </div>
      </Alert>
    </Snackbar>
  );
};

export default Toast;
