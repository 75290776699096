const SAVED_PATH_ID = 'SAVED_PATH';

const savePath = (): void => {
    window.sessionStorage.setItem(SAVED_PATH_ID, window.location.href);
}

const getPath = (): string => {
    const path = window.sessionStorage.getItem(SAVED_PATH_ID) || '/';
    window.sessionStorage.removeItem(SAVED_PATH_ID);
    return path;
}

const useSavedPath = () => {
    return {
        savePath,
        getPath,
    }
}

export default useSavedPath;
