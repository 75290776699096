import * as React from "react";
import "./ConfirmationModal.scss";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Type for props of ConfirmationModal component.
 * 
 * @param headlineText
 * Optional text that will appear are as an h4 at the top of the confirmation 
 * modal.
 * 
 * @param bodyText
 * Optional text that will appear as a paragraph in the middle of the
 * confirmation modal.
 * 
 * @param children
 * Optional children that will appear in the middle of the confirmation modal.
 * This can be any valid jsx.
 * 
 * @param cancelButtonText
 * Optional text that will appear in an outlined button to the left. If no text
 * is provided, no outlined button will be rendered.
 * 
 * @param onCancel
 * Optional function to run when the outlined button to the left is clicked. If
 * cancelButtonText is provided but no onCancel button, component will throw an
 * error.
 * 
 * @param confirmationButtonText
 * Optional text taht will appear in a solid button to the right. If no text is
 * provided, no solid button will be rendered.
 * 
 * @param onConfirm
 * Optional function to run when the solid button is pressed. If
 * confirmationButtonText is provided but no onConfirm function, the component
 * will throw an error.
 * 
 * @param isLoading
 * Optional boolean that will cause the button row to be replaced by a spinner
 * when true.
 */
export type ConfirmationModalProps = {
  headlineText?: string,
  bodyText?: string,
  children?: React.ReactNode,
  cancelButtonText?: string,
  onCancel?: () => void,
  confirmationButtonText?: string,
  onConfirm?: () => void,
  isLoading?: boolean,
};

/**
 * Returns a button row for the confirmation modal (cancel and/or confirm
 * buttons).
 * 
 * This is a separate, exported function so that the button row can be passed as
 * one of the optional children to the ConfirmationModal component, allowing the
 * onCancel and onConfirm functions to more easily interact with other children
 * (for example setting or referring to some kind of state).
 */
export const confirmationModalButtonRow = (
  cancelButtonText: string,
  onCancel: () => void,
  // Can be a ReactNode to allow for cases where it should be a string followed
  // by an icon.
  confirmationButtonText: string | React.ReactNode,
  onConfirm: () => void,
  loading: boolean = false,
) => {
  if (cancelButtonText && !onCancel) {
    throw new Error('Cancel button text supplied, but no onCancel function.')
  }

  if (confirmationButtonText && !onConfirm) {
    throw new Error('Confirmation button text supplied, but no onConfirm function.')
  }

  // 30px approximates the height of the buttons so that the row doesn't appear
  // to shift size if it switches between loading/buttons.
  const SPINNER_SIZE_IN_PIXELS = 30;

  const row = () => {
    return (
      <>
        {cancelButtonText &&
          <Button
            variant="outlined"
            onClick={onCancel}
            type="reset"
            data-testid="cancel-button"
          >
            {cancelButtonText}
          </Button>
        }
        {confirmationButtonText &&
          <Button
            variant="filled"
            onClick={onConfirm}
            type="submit"
            data-testid="confirm-button"
          >
            {confirmationButtonText}
          </Button>
        }
      </>
    )
  }

  return (
    <div className="row">
      {loading
        ? <div
          className="row loading-div"
          data-testid="loading-div"
        >
          <CircularProgress size={SPINNER_SIZE_IN_PIXELS} />
        </div>
        : row()
      }
    </div>
  )
}

/**
 * Renders a confirmation screen with flexible headline, body text, hierarchy of
 * children, and cancel and confirmation buttons.
 */
const ConfirmationModal = ({
  headlineText,
  bodyText,
  children,
  cancelButtonText,
  onCancel,
  confirmationButtonText,
  onConfirm,
  isLoading = undefined,
}: ConfirmationModalProps) => {
  return (
    <div
      className="confirmation-modal"
      data-testid="confirmation-modal-wrap"
    >
      {headlineText &&
        <Typography
          display={'block'}
          variant="heading4-light"
          data-testid="headline-text"
        >
          {headlineText}
        </Typography>
      }
      {bodyText &&
        <Typography
          variant="paragraph1-light"
          data-testid="body-text"
        >
          {bodyText}
        </Typography>
      }
      {children}
      {(cancelButtonText || confirmationButtonText) && confirmationModalButtonRow(
        cancelButtonText,
        onCancel,
        confirmationButtonText,
        onConfirm,
        isLoading,
      )}
    </div >
  );
};

export default ConfirmationModal;
