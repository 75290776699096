import { TextFieldProps, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import './FormAutocomplete.scss';
import { StyledAutocomplete } from './StyledAutocomplete';

/**
 * Type for props of autocomplete data
 */
export type DataProps = {
  // This should be unique in the list of choice - MUI selects based on label,
  // not on the value.
  label: string;

  // TODO: 
  // Does this need to be a primitive type like string or number that is 
  // immediately obvious as a unique value?
  value: any;
};

/**
 * Type for props of AutoComplete component.
 
  label: Default values that appears in the textfield of the autocomplete when no values are selected
  data: Data passed to the component that determines the popper menu options
  width: Width of the autocomplete component
  size?: Size options provided by mui are small or medium
  multiple?: This prop is a boolean that dictates whethere or not multiple values can be selected by the user
  disableCloseOnSelect?: This prop dictates if the autocomplete popper menu closes on selection of a value. It's recommended to set it to true for autocomplete where multiple={false} and false when multiple={true}
  renderOption: (selected, label) => JSX.Element | JSX.Element[];
  renderTag?: (val: DataProps) => JSX.Element | JSX.Element[];
  control: The control object is exposed by the useForm hook and is passed to all form Controllers
  controlLabel: The controlLabel is a string that connects the component to the form state, the string should match the corresponding key in the defaultValues object and form props interface. 

 */
type AutoCompleteProps = {
  // Text show in the textfield of the autcomplete
  label: string;
  data: DataProps[];
  width: number;
  size?: 'small' | 'medium';
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  //Customizes the options in the autocomplete menu, default provided in StyledAutocomplete.tsx
  renderOption?: (selected, label) => JSX.Element | JSX.Element[];
  renderTag?: (val: DataProps) => JSX.Element | JSX.Element[];
  // Any is used due to react-hook-form library error, see github issue #4965 for more details
  control: any;
  // useForm label that connects component to form state
  controlLabel: string;
  // Optional string displayed above autocomplete
  displayName?: string;
  // disable drop down action
  disabled?: boolean;
  //MUI textfield props
  textfieldProps?: TextFieldProps;
};
const defaultRenderTag = (val) => {
  return val.map((option: DataProps, index: number) => <Typography key={index} sx={{ color: 'white' }}>{`${index !== 0 ? ',' : ''} ${option.label}`}</Typography>)
}

const FormAutocomplete = ({ label, data, width, size = 'small', multiple = true, disableCloseOnSelect = false, renderOption, control, controlLabel, renderTag = defaultRenderTag, displayName, disabled = false, textfieldProps = {}
}: AutoCompleteProps) => {

  return (
    <Controller
      key={controlLabel}
      name={controlLabel}
      control={control}
      render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
        return (
          <StyledAutocomplete field={field} disabled={disabled} onChange={onChange} width={width} size={size} label={label} data={data} multiple={multiple} disableCloseOnSelect={disableCloseOnSelect} renderOption={renderOption} renderTag={renderTag} value={value} displayName={displayName} textfieldProps={textfieldProps} error={error} />
        )
      }}
    />
  )
};

export default FormAutocomplete;