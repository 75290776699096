import * as React from 'react';
import { Menu, PopoverOrigin } from '@mui/material';
import './CollapsibleMenu.scss';

/**
 * Type for props of CollapsibleMenu component.
 */
type CollapsibleMenuProps = {
  anchorEl: Element | null;
  handleClose: (event: React.MouseEvent<Element, MouseEvent>) => void;
  MenuContent: JSX.Element;
  width?: number;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  customMenuStyles?: React.CSSProperties;
};

/**
 * Menu with dynamic content
 */
const CollapsibleMenu = ({
  anchorEl,
  handleClose,
  MenuContent,
  width = 380,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  customMenuStyles =  {
    backgroundColor: 'var(--base-400)',
    width: width,
    color: 'var(--white)',
    justifyContent: 'flex-end',
  },
}: CollapsibleMenuProps) => {
  const menuStyles = {
    style: customMenuStyles
  };
  const open = Boolean(anchorEl);
  return (
    <Menu
      MenuListProps={menuStyles}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {MenuContent}
    </Menu>
  );
};

export default CollapsibleMenu;
