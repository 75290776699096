import { getConfig } from '@aiops/root-config';
import s2pIcon from '../assets/icons/s2pIcon.svg';
import daasAdmIcon from '../assets/icons/daasAdmIcon.svg';
import finFCTAIcon from '../assets/icons/finFCTAIcon.svg';
import genericMicrosolutionIcon from '../assets/icons/genericMicrosolutionIcon.svg';

/**
 * Concatenates a string or array of strings to the root config's endpointBaseUrl,
 * returning the full url.
 * 
 * Given '/endpoint', returns 'https://example.com/endpoint'. Given ['first',
 * 'second', 'third'], returns 'https://example.com/first/second/third'.
 * 
 * The output will be correct whether or not the endpointBaseUrl and/or 
 * string(s) include any leading or trailing slahes.
 * 
 * @param endpoint 
 * String or array of strings to append to the endpointBaseUrl. Can include 
 * leading or trailing slashes, or not, it doesn't matter.
 * 
 */
export const appendToBaseUrl = (endpoint: string | string[]) => {
    const asArr = Array.isArray(endpoint) ? endpoint : [endpoint];
    return concatenateStringsWithSlashes([getConfig().endpointBaseUrl, ...asArr]);
}

/**
 * Concatenates an array of strings into a single string, removing any leading
 * or trailing slashes and ensuring that there is only one slash between each
 * string.
 * 
 * For example: ['a', 'b', 'c'], ['/a', 'b/', '/c/'],['a', 'b/c']
 * ['//a//', '//b//', '//c//'] all return 'a/b/c'.
 * 
 * Empty strings and anything other than a string are ignored.
 * 
 * @param arr 
 * Array of strings to combine.
 * 
 */
export const concatenateStringsWithSlashes = (arr: string[]) => {
    return arr.filter((str) => typeof str === 'string')
        .map((str) => str.trim())
        .map((str) => {
            let newStr = str;
            while (newStr.startsWith('/')) {
                newStr = newStr.slice(1);
            }
            while (newStr.endsWith('/')) {
                newStr = newStr.slice(0, newStr.length - 1);
            }
            return newStr;
        }).filter((str) => str)
        .join('/');
}

/**
 * Mapper to get the icon for each process area
 */
export const PROCESS_AREA_ICONS = {
    S2P_ICON: s2pIcon,
    Q2C_ICON: finFCTAIcon,
    DAASADM_ICON: daasAdmIcon,
    FIN_FCTA_ICON: finFCTAIcon,
    GENERIC_ICON: genericMicrosolutionIcon,
};
