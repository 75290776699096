import { useState, useContext, createContext, } from "react";
import Modal from "../../components/Modal";
import {
  default as ConfirmationModal,
  ConfirmationModalProps,
} from "../../components/ConfirmationModal";

type ModalContextType = {
  openModal?: (arg: ConfirmationModalProps | null) => void;
  closeModal?: () => void;
  blockCloseModal?: (arg: boolean) => void;
}

/**
 * The actual context object.
 */
const ModalContextCtxtObj = createContext<ModalContextType>({});

/**
 * Hook to access the ModalContext context object.
 */
export const useModal = () => {
  return useContext(ModalContextCtxtObj);
};

/**
 * Renders the ModalContext's Provider.
 */
const ModalProvider = ({ children }) => {
  // Sample value/setter for state.
  const [currentModal, setCurrentModal] = useState<ConfirmationModalProps | null>(null);
  const [modalCloseIsBlocked, setModalCloseIsBlocked] = useState<boolean>(false);

  const openModal = (props: ConfirmationModalProps) => setCurrentModal(props);
  const closeModal = () => {
    // Reset this setting every time the modal is closed - you have to actively
    // block it from being closed each time you open it.
    // This is to avoid persisting an unwanted or unexpected blocKCloseModal
    // state that would affect every modal opened in this context.
    setModalCloseIsBlocked(false);
    setCurrentModal(null)
  }

  // Takes a boolean that determines whether the modal is blocked from being
  // closed by clicking outside of it or hitting the escape key.
  // Regardless of this setting, the modal can always be closed with the
  // closeModal function.
  // This setting resets every time the modal is closed (ie if you set this to
  // true, then close the modal, then open it again, it will once again be 
  // possible to close the modal by clickging outside of it).
  const blockCloseModal = (val: boolean) => setModalCloseIsBlocked(val);

  const value = {
    openModal,
    closeModal,
    blockCloseModal,
  };

  return (
    <ModalContextCtxtObj.Provider
      value={value}
    >
      <Modal
        open={!!currentModal}
        onClose={modalCloseIsBlocked ? () => null : closeModal}
      >
        <ConfirmationModal
          {...currentModal}
        />
      </Modal>
      {children}
    </ModalContextCtxtObj.Provider>
  );
};

export default ModalProvider;
