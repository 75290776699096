import { useAuth, AuthContextType } from '../../contexts/AuthContext';
import "./AuthCheckWrap.scss";

/**
 * Type for props of AuthCheckWrap component.
 */
export type AuthCheckWrapProps = {
  renderWithAuthCheck: (auth: AuthContextType) => JSX.Element | null;
};

/**
 * Wraps any children; runs the onAuthCheck function in a useEffect, taking
 * the results of useAuth() as an argument.
 * 
 * @param renderWithAuthCheck
 * Function that takes the result of useAuth() as input and returns whatever
 * children this wrapper should render.
 */
const AuthCheckWrap = ({ renderWithAuthCheck }: AuthCheckWrapProps) => {

  const auth = useAuth();

  return (
    renderWithAuthCheck(auth)
  );
};

export default AuthCheckWrap;
