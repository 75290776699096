// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q8i39MTlFqYePtCP31LWqw\\=\\= button[class*=MuiTab-root]{align-items:center}.q8i39MTlFqYePtCP31LWqw\\=\\= button[class*=MuiTab-root] p{color:var(--tab-inactive-text);font-weight:var(--tab-inactive-font-weight);text-transform:capitalize}.q8i39MTlFqYePtCP31LWqw\\=\\= button[class*=MuiTab-root][class*=Mui-disabled]{opacity:.5}.q8i39MTlFqYePtCP31LWqw\\=\\= button[class*=MuiTab-root][class*=Mui-selected] p{color:var(--tab-active-text);font-weight:var(--tab-active-font-weight)}.q8i39MTlFqYePtCP31LWqw\\=\\= *[class*=MuiTabs-indicator]{background-color:var(--tab-active)}", "",{"version":3,"sources":["webpack://./src/components/Tabs/TabSwitcher.module.scss"],"names":[],"mappings":"AACE,uDACE,kBAAA,CACA,yDACE,8BAAA,CACA,2CAAA,CACA,yBAAA,CAGF,4EACE,UAAA,CAGF,8EACE,4BAAA,CACA,yCAAA,CAIJ,wDACE,kCAAA","sourcesContent":[".tabSwitcher {\n  button[class*=\"MuiTab-root\"] {\n    align-items: center;\n    p {\n      color: var(--tab-inactive-text);\n      font-weight: var(--tab-inactive-font-weight);\n      text-transform: capitalize;\n    }\n\n    &[class*=\"Mui-disabled\"] {\n      opacity: 0.5;\n    }\n\n    &[class*=\"Mui-selected\"] p {\n      color: var(--tab-active-text);\n      font-weight: var(--tab-active-font-weight);\n    }\n  }\n\n  *[class*=\"MuiTabs-indicator\"] {\n    background-color: var(--tab-active);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabSwitcher": "q8i39MTlFqYePtCP31LWqw=="
};
export default ___CSS_LOADER_EXPORT___;
