import { useState, useContext, createContext, } from "react";
import Toast from '../../components/Toast';

/**
 * Type for the ToastContext.
 */
type ToastContextType = {
  setCurrentToast?: (arg: ToastMsg) => void,
}

/**
 * The actual context object.
 */
const ToastContextCtxtObj = createContext<ToastContextType>({});

/**
 * Hook to access the ToastContext context object.
 */
export const useToast = () => {
  return useContext(ToastContextCtxtObj);
};

export type ToastMsg = {
  severity: 'success' | 'error',
  message: string,
  autoHideDuration?: number,
  children?: JSX.Element | JSX.Element[],
}

/**
 * Renders the ToastContext's Provider.
 */
const ToastProvider = ({ children }) => {
  const [currentToast, actuallySetCurrentToast] = useState(null);

  const setCurrentToast = (arg: ToastMsg | ToastMsg[]) => {
    const toSet = Array.isArray(arg) ? arg : [arg];
    actuallySetCurrentToast(toSet);
  };

  const closeAllToasts = () => actuallySetCurrentToast(null);

  const closeToastAtIndex = (event: any, idx: number) => {
    event.stopPropagation();
    const toSet = currentToast.filter((t: any, i: number) => i !== idx);
    actuallySetCurrentToast(toSet.length > 0 ? toSet : null);
  }

  const value = {
    setCurrentToast,
    closeAllToasts,
  };

  return (
    <ToastContextCtxtObj.Provider
      value={value}
    >
      {currentToast && (Array.isArray(currentToast) && currentToast.length > 0) &&
        <>
          {currentToast.map((t: any, idx: number) => (
            <Toast
              {...t}
              key={t.message}
              open={true}
              closeAllToasts={closeAllToasts}
              closeThisToast={(e) => closeToastAtIndex(e, idx)}
              index={idx}
            />
          ))}
        </>
      }
      {children}
    </ToastContextCtxtObj.Provider>
  );
};

export default ToastProvider;
