import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading1: React.CSSProperties;
    heading2: React.CSSProperties;
    'heading2-titles': React.CSSProperties;
    heading3: React.CSSProperties;
    'heading3-light': React.CSSProperties;
    heading4: React.CSSProperties;
    'heading4-light': React.CSSProperties;
    heading5: React.CSSProperties;
    'heading5-light': React.CSSProperties;
    heading6: React.CSSProperties;
    'heading6-bold': React.CSSProperties;
    'heading6-light': React.CSSProperties;
    heading7: React.CSSProperties;
    'heading7-light': React.CSSProperties;
    subheading1: React.CSSProperties;
    paragraph1: React.CSSProperties;
    'paragraph1-bold': React.CSSProperties;
    'paragraph1-light': React.CSSProperties;
    'caption-italic': React.CSSProperties;
    caption1: React.CSSProperties;
    caption1b: React.CSSProperties;
    caption1c: React.CSSProperties;
    caption1d: React.CSSProperties;
    caption1e: React.CSSProperties;
    'form-field-label': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heading1?: React.CSSProperties;
    heading2?: React.CSSProperties;
    'heading2-titles'?: React.CSSProperties;
    heading3?: React.CSSProperties;
    'heading3-light'?: React.CSSProperties;
    heading4?: React.CSSProperties;
    'heading4-light'?: React.CSSProperties;
    heading5?: React.CSSProperties;
    'heading5-light'?: React.CSSProperties;
    heading6?: React.CSSProperties;
    'heading6-bold'?: React.CSSProperties;
    'heading6-light'?: React.CSSProperties;
    heading7?: React.CSSProperties;
    'heading7-light'?: React.CSSProperties;
    subheading1?: React.CSSProperties;
    paragraph1?: React.CSSProperties;
    'paragraph1-bold'?: React.CSSProperties;
    'paragraph1-light'?: React.CSSProperties;
    'caption-italic'?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption1b?: React.CSSProperties;
    caption1c?: React.CSSProperties;
    caption1d?: React.CSSProperties;
    caption1e?: React.CSSProperties;
    'form-field-label'?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlined: true;
    filled: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading1: true;
    heading2: true;
    'heading2-titles': true;
    heading3: true;
    'heading3-light': true;
    heading4: true;
    'heading4-light': true;
    heading5: true;
    'heading5-light': true;
    heading6: true;
    'heading6-bold': true;
    'heading6-light': true;
    heading7: true;
    'heading7-semibold': true;
    'heading7-normal': true;
    'heading7-light': true;
    subheading1: true;
    'subheding1-light': true;
    paragraph1: true;
    'paragraph1-bold': true;
    'paragraph1-light': true;
    'caption-italic': true;
    caption1: true;
    caption1b: true;
    caption1c: true;
    caption1d: true;
    caption1e: true;
    'form-field-label': true;
  }
}

/* FONT WEIGHTS */
const extraBold = '800';
const bold = '700';
const semiBold = '600';
const normal = '400';
const light = '300';

const aiopsTheme = {
  palette: {
    text: {
      primary: '#FFFFFF', // white
      secondary: '#D3D1E5', // 'hibiscus'
    },
    primary: {
      main: '#2D2A4A',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#BFF3D2',
    },
  },
  typography: {
    heading1: {
      fontSize: '128px',
      lineHeight: '156px',
      fontWeight: extraBold,
    },
    heading2: {
      fontSize: '64px',
      lineHeight: '80px',
      fontWeight: extraBold,
    },
    'heading2-titles': {
      fontSize: '44px',
      lineHeight: '48px',
      fontWeight: semiBold,
    },
    heading3: {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: bold,
    },
    'heading3-light': {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: light,
    },
    heading4: {
      fontSize: '28px',
      lineHeight: '32px',
      fontWeight: bold,
    },
    'heading4-light': {
      fontSize: '28px',
      lineHeight: '32px',
      fontWeight: light,
    },
    heading5: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: extraBold,
    },
    'heading5-light': {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: light,
    },
    heading6: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: extraBold,
    },
    'heading6-bold': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: bold,
    },
    'heading6-light': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: light,
    },
    heading7: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: extraBold,
    },
    'heading7-semibold': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: semiBold,
    },
    'heading7-normal': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: normal,
    },
    'heading7-light': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: light,
    },
    subheading1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: extraBold,
    },
    'subheading1-semibold': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: semiBold,
    },
    'subheading1-normal': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: normal,
    },
    'subheading1-light': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: light,
    },
    paragraph1: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: normal,
    },
    'paragraph1-bold': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: bold,
    },
    'paragraph1-light': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: light,
    },
    'caption-italic': {
      fontSize: '14px',
      lineHeight: '18px',
      fontStyle: 'italic',
      fontWeight: normal,
    },
    caption1: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: extraBold,
    },
    caption1b: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: bold,
    },
    caption1c: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: normal,
    },
    caption1d: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: light,
    },
    caption1e: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: normal,
    },
    // Form Field Label - used for form field labels
    'form-field-label': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: semiBold
    },
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: 'var(--bright-lupine)',
            color: 'var(--white)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' as const },
          style: {
            color: 'var(--hibiscus)',
            border: '1px solid var(--hibiscus)',
            '&:hover': {
              border: '1px solid var(--white)',
            },
            '&.Mui-disabled': {
              color: 'var(--sad-fig)',
              border: '1px solid var(--sad-fig)',
            },
          },
        },
        {
          props: { variant: 'filled' as const },
          style: {
            color: 'var(--darkberry)',
            background: 'var(--hibiscus)',
            '&.Mui-disabled': {
              color: 'var(--sad-lilac)',
              backgroundColor: 'var(--sad-fig)',
            },
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        // Explicitly map the Typography variants to HTML tags
        variantMapping: {
          heading1: 'h1',
          heading2: 'h2',
          'heading2-titles': 'h2',
          heading3: 'h3',
          'heading3-light': 'h3',
          heading4: 'h4',
          'heading4-light': 'h4',
          heading5: 'h5',
          'heading5-light': 'h5',
          heading6: 'h6',
          'heading6-bold': 'h6',
          'heading6-light': 'h6',
          heading7: 'h6',
          'heading7-light': 'h6',
          subheading1: 'h6',
          paragraph1: 'p',
          'paragraph1-bold': 'p',
          'paragraph1-light': 'p',
          'caption-italic': 'p',
          caption1: 'p',
          caption1b: 'p',
          caption1c: 'p',
          caption1d: 'p',
          caption1e: 'p',
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        sx: {
          color: 'var(--hibiscus)',
          margin: 'auto',
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        sx: {
          backgroundColor: 'var(--sad-lilac)',
        },
      },
    },
  },
};

const muiTheme = (overrides = {}) =>
  createTheme({
    ...aiopsTheme,
    ...overrides,
  });

export default muiTheme;
