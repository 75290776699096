import { Typography, AppBarProps, CardProps } from '@mui/material';
import FormCard from './FormCard';
import { FormFooterContent } from './FormFooterContent';
import Footer from '../Footer';
import './Form.scss';

type FormProps = {
  title?: string;
  children: JSX.Element;
  onSubmit: any;
  confirmText?: string;
  exitText?: string;
  confirmDisabled?: boolean;
  confirmOnClick?: () => void;
  exitOnClick?: () => void;
  width?: string | number;
  // boolean to hide or show footer
  showFooter?: boolean;
  //MUI footer props
  footerProps?: AppBarProps;
  // MUI card props
  cardProps?: CardProps;
};

/**
 * See README for form implementation details and example
 */

const Form = ({ title, children, onSubmit, confirmText, exitText, confirmDisabled = false, confirmOnClick, exitOnClick, width, showFooter = true, footerProps = {}, cardProps = {} }: FormProps) => {

  // function that accounts for hidden footer when determining form content height
  const getContentHeight = () => {
    if (showFooter) return 'calc(100vh - var(--form-footer-height) - var(--header-height))';
    return 'calc(100vh - var(--header-height))';
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='form-wrapper' style={{ width: width, maxHeight: getContentHeight() }}>
        {title && <Typography className='title' variant='h3'>{title}</Typography>}
        <FormCard cardProps={cardProps}>
          {children}
        </FormCard>
      </div>
      {showFooter && <Footer footerProps={footerProps}><FormFooterContent confirmText={confirmText} exitText={exitText} confirmDisabled={confirmDisabled} confirmOnClick={confirmOnClick} exitOnClick={exitOnClick} /></Footer>}
    </form>
  );
};
export default Form