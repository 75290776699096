import * as React from "react";
import "./SidePanelWrap.scss";

/**
 * Type for props of SidePanelWrap component.
 * 
 * @param width
 * Width of the side panel wrapper, in pixels.
 * 
 * @param children
 * Contents of the side panel wrapper.
 */
export type SidePanelWrapProps = {
  children?: React.ReactNode;
  width: number;
};

/**
 *  Container that holds the contents of a side panel.
 */
const SidePanelWrap = ({
  children,
  width,
}: SidePanelWrapProps) => {
  const style = {
    width: `${width}px`,
  }

  return (
    <div
      className="side-panel-wrap"
      style={style}
    >
      {children}
    </div>
  );
};

export default SidePanelWrap;
