import * as React from "react";
import "./StickyAppFooter.scss";

/**
 * Type for props of StickyAppFooter component.
 * 
 * @param children
 * Whatever content should be in the sticky footer.
 * 
 * @param style
 * Optional inline styles to apply to inner container.
 */
export type StickyAppFooterProps = {
  children?: React.ReactElement | React.ReactElement[],
  style?: React.CSSProperties,
};

/**
 * Renders a container that sticks to the bottom of the screen.
 */
const StickyAppFooter = ({ children, style }: StickyAppFooterProps) => {
  // Outer container is the purple bar that goes the entire width of the screen.
  // Inner container is responsive to app width.
  return (
    <div className="sticky-app-footer-wrap">
      <div
        className="sticky-app-footer-inner-container"
        style={style}
      >
        {children}
      </div>
    </div >
  );
};

export default StickyAppFooter;
