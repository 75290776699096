import { RadioGroup, FormControlLabel, FormControl, FormHelperText, Radio, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import './FormRadioButtonGroup.scss';
import IconRadioChecked from '../../assets/icons/radioChecked.svg';
import IconRadioUnchecked from '../../assets/icons/radioUnchecked.svg';
import { ReactSVG } from "react-svg";

const RadioChecked = () =>  <ReactSVG src={IconRadioChecked} wrapper="svg" />;
const RadioUnchecked = () => <ReactSVG src={IconRadioUnchecked} wrapper="svg" />;

type RadioButtonGroupProps = {
  groupData: {
    value: string;
    label: string;
  }[];
  field: any;
  onChange: (...event: any[]) => void;
  value: string;
  error?: FieldError;
  isDisabled?: boolean;
};

export const RadioGroupWrapper = ({ field, onChange, value, groupData, error, isDisabled }: RadioButtonGroupProps) => {
  return (
    <FormControl error={!!error}>
      <RadioGroup
        {...field}
        onChange={(_event, value) => onChange(value)}
        value={value}
        row
      >
        {groupData.map((option) => {
          return (
            <FormControlLabel
              disabled={isDisabled}
              key={option.value}
              value={option.value}
              control={<Radio className='radio' disableRipple checkedIcon={<RadioChecked />} icon={<RadioUnchecked />}/>}
              label={<Typography className='label'>{option.label}</Typography>} />
          )
        })}
      </RadioGroup>
      {!!error && <FormHelperText className='error-text'>{error?.message}</FormHelperText>}
    </FormControl>
  )
};
