import React, { useState, useContext, createContext } from "react";
import "./AppContainerWithSidePanel.scss";
import useWindowSize from '../../hooks/useWindowSize';
import layoutSizes from '../../constants/layoutSizes';
import SidePanelWrap from "../SidePanelWrap";

type SidePanelContextType = {
  sidePanelOpen?: boolean,
  setSidePanelOpen?: (arg: boolean) => void,
  toggleSidePanelOpen?: () => void,
}

const SidePanelContext = createContext<SidePanelContextType>({})

export const useSidePanel = () => {
  return useContext(SidePanelContext);
}

/**
 * Type for props of AppContainerWithSidePanel component.
 */
export type AppContainerWithSidePanelProps = {
  defaultOpen?: boolean,
  sidePanelWidthOpen?: number,
  sidePanelWidthClosed?: number,
  sidePanelContents?: React.ReactNode | React.ReactNode[],
  children?: React.ReactNode | React.ReactNode[],
};

/**
 * Container that wraps an app (the children elements it's passed) and also 
 * renders a side panel, wrapping both in a context that can be accessed (using
 * the useSidePanel hook) for descendent components that need to know whether
 * the side panel is open or closed (for example the content in the panel might
 * be rendered or styled differently depending on if it's open or closed).
 */
const AppContainerWithSidePanel = ({
  defaultOpen = false,
  sidePanelWidthOpen = layoutSizes.SIDE_PANEL_WIDTH_OPEN,
  sidePanelWidthClosed = layoutSizes.SIDE_PANEL_WIDTH_CLOSED,
  sidePanelContents,
  children,
}: AppContainerWithSidePanelProps) => {
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(defaultOpen);

  const toggleSidePanelOpen = () => {
    setSidePanelOpen(!sidePanelOpen);
  }

  // *** PROGRAMMATIC LAYOUT ***
  // TODO: Find a way to do this with an SCSS mixin instead of JavaScript
  const { appWidth } = useWindowSize();

  // The width of just the side panel
  const sidePanelWidth = sidePanelOpen ? sidePanelWidthOpen : sidePanelWidthClosed;

  // The width of everything other than the side panel
  const appWrapWidth = appWidth - sidePanelWidth;

  const value = {
    sidePanelOpen,
    setSidePanelOpen,
    toggleSidePanelOpen,
  }

  return (
    <div className="row app-with-side-panel-wrap">
      <SidePanelContext.Provider value={value}>
        <SidePanelWrap
          width={sidePanelOpen ? sidePanelWidthOpen : sidePanelWidthClosed}
        >
          {sidePanelContents}
        </SidePanelWrap>
        <div
          className="col wrapped-app-wrap"
          style={{
            width: `${appWrapWidth}px`,
          }}
        >
          {children}
        </div>
      </SidePanelContext.Provider>
    </div >
  );
};

export default AppContainerWithSidePanel;
