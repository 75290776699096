import { Switch, SwitchProps } from '@mui/material';
import './StyledSwitch.scss'

/**
 * Type for props of StyledSwitchProps component.
 * checked: boolean that dictates where switch is in active or inactive state
 * onChange: function to run on click on the switch
 * switchProps: optional MUI switch props
 * Null ref passed to get rid of console warning
 */
type StyledSwitchProps = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  switchProps?: SwitchProps;
  ref: null;
}

const StyledSwitch = ({ checked, onChange, switchProps = {} }: StyledSwitchProps) => {
  return (
    <Switch className='switch' onChange={onChange} checked={checked}  {...switchProps} />
  )
}
export default StyledSwitch;