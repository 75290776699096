import * as React from "react";
import TableHeaderCell, { TableHeaderCellProps } from '../TableHeaderCell';
import Checkbox from '../Checkbox';
import { Selectability } from "../Table";
import styles from '../Table/Table.module.scss';

/**
 * Type for props of TableHeader component.
 */
type TableHeaderProps = {
  // The keys, in order, that correspond to the key/value pair that should be
  // used to populate data in each column.
  columnKeys: Array<string | number>,

  // Object where each key corresponds to a key in the columnKeys arrays and
  // each value corresponds to the TableHeaderCellProps object passed to the 
  // header cell for that column.
  headerCells: Record<string, TableHeaderCellProps>,

  // The table's selectability object, if any.
  selectability?: Selectability,
};

/**
 *  Renders header row of Table component.
 */
const TableHeader = ({
  columnKeys,
  headerCells,
  selectability = undefined,
}: TableHeaderProps) => {

  const {
    selectAllCheckbox,
    selectAllCheckboxValue,
    selectAllCheckboxIndeterminate,
    selectAllCheckboxDisabled,
    onSelectAllToggle,
  } = selectability || {};

  return (
    <tr
      className={`${styles["aiops-table-header"]} aiops-table-header row sticky`}
      data-testid="table-header"
    >
      {selectability &&
        <th className={`${styles["aiops-table-row-checkbox"]} aiops-table-row-checkbox col`}>
          {selectAllCheckbox && <Checkbox
            checked={selectAllCheckboxValue}
            indeterminate={selectAllCheckboxIndeterminate}
            disabled={selectAllCheckboxDisabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              // Override default behavior when clicked in indeterminate state.
              // Default is clicking when indeterminate changes to checked. In
              // this case designers want clicking indeterminate to change to
              // unchecked (ie to deselect all).
              const newState = selectAllCheckboxIndeterminate ? false : checked;
              onSelectAllToggle(newState);
            }}
          />}
        </th>
      }
      {columnKeys.map((key, idx) => {
        return (
          <TableHeaderCell
            {...headerCells[key]}
            key={idx + 1}
          />
        )
      })}
    </tr>
  );
};

export default TableHeader;
