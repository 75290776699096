import * as React from "react";
import "./ErrorPage.scss";
import { Button, Typography } from "@mui/material";
import defaultErrorIcon from "../../assets/icons/icon-error.svg";

/**
 * Type for props of ErrorPage component.
 * 
 * @param icon
 * Icon to display on the Error Page. Will default to a generic error icon if 
 * not provided.
 * 
 * @param noIcon
 * If true, will not display an icon on the Error Page.
 * 
 * @param title
 * Title to display on the Error Page. Will default to "Error" if not provided.
 * 
 * @param message
 * Message to display on the Error Page.
 * 
 * @param onClick
 * Function to call when the button is clicked. Both a function and a buttonText
 * must be provided to render a button.
 * 
 * @param buttonText
 * Text to display on the button. Both a function and a buttonText must be
 * provided to render a button.
 */
export type ErrorPageProps = {
  icon?: any,
  noIcon?: boolean,
  title?: string,
  message?: string,
  onClick?: () => void,
  buttonText?: string,
};

/**
 * Renders a generic error page.
 */
const ErrorPage = ({ icon, noIcon = false, title, message, onClick, buttonText }: ErrorPageProps) => {

  if ((onClick && !buttonText) || (!onClick && buttonText)) {
    console.error("ErrorPage: you have provided either an onClick or a buttonText prop, but not both. Both must be provided to render a button.");
  }

  if (icon && noIcon) {
    console.error("ErrorPage: you have provided both an icon and noIcon prop. If you want to remove the icon, do not provide an icon prop.");
  }

  return (
    <div className="error-page-wrap">
      <div className="col error-page">
        {!noIcon && <img src={icon || defaultErrorIcon} alt="Error" />}
        <Typography variant="heading4-light">
          {title || "Error"}
        </Typography>
        {message && <Typography variant="paragraph1">
          {message}
        </Typography>}
        {onClick && buttonText && <Button variant="outlined" onClick={onClick}>{buttonText}</Button>}
      </div>
    </div>
  );
};

export default ErrorPage;
