import {
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
// @ts-ignore
import * as auth from "@aiops/auth-util";
import { CircularProgress, Typography } from "@mui/material";
import SignIn from "../../components/SignIn";
import { getConfig } from "@aiops/root-config";
import { COGNITO, KEYCLOAK } from "./constants";

/**
 * Type for the AuthContext
 */
export type AuthContextType = {
  userIsSignedIn?: boolean,
  authToken?: string | null,
  permissions?: Record<string, any> | null,
  getAppPermissions?: (appId: string) => Promise<Record<string, boolean>>,
  getIdToken?: () => string | null,
  getUser?: () => Promise<Record<string, string> | null>,
  signOut?: () => Promise<void>,
};

/**
 * The actual context object.
 */
const AuthContextCtxtObj = createContext<AuthContextType>({});

/**
 * Hook to access the AuthContext context object.
 */
export const useAuth = () => {
  return useContext(AuthContextCtxtObj);
};

export type AuthContextProviderTypes = {
  children: React.ReactNode | React.ReactNode[],
  requireAuth?: boolean,
  checkPermissions?: (permissions: Record<string, any>) => boolean,
}

/**
 * Renders the AuthContext's Provider.
 */
const AuthContextProvider = ({
  children,
  requireAuth = true,
  checkPermissions,
}: AuthContextProviderTypes) => {
  const { authMethod } = getConfig();
  // Only show loading if auth is required.
  const [showLoading, setShowLoading] = useState<boolean>(requireAuth);
  const [userIsSignedIn, setUserIsSignedIn] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<Record<string, any> | null>(auth.getPermissions());
  const [error, setError] = useState<string | null>(null);

  const getUser = async (): Promise<Record<string, string> | null> => {
    return await auth.getUser();
  }

  const signOut = async () => {
    const res = await auth.signOut();
    setAuthToken(null);
    setPermissions(null);
  }

  /**
   * Whenever the auth status changes (user signs in or out), update the state
   * here.
   * 
   * If the user is signed in but permissions are null, trigger the auth-util
   * app to check and update those permissions, which are necessary to know for
   * all users who are signed in.
   * 
   * Creating this Rube Goldberg machine here means that it only happens here.
   * Apps that use the useAuth hook don't have to check whether permissions are
   * null and if so explicitly trigger the auth-util app to update permissions.
   * They can just destructure the permissions object and it will happen
   * automatically.
   */
  const checkAuthStatus = async () => {
    setError(null);
    const signedIn = await auth.userIsSignedIn();
    setUserIsSignedIn(signedIn);
    setShowLoading(false);
    if (signedIn) {
      const perms = auth.getPermissions();
      setPermissions(perms);
      if (perms === null) {
        try {
          await auth.updateAppAccessPermissions();
        } catch (error) {
          setError("Unable to fetch app access permissions.");
        }
      }
    }
  }

  const checkKeycloakStatus = async () => {
    setError(null);
    await auth.initKeycloak();
    const signedIn = await auth.userIsSignedIn();
    setUserIsSignedIn(signedIn);
    setShowLoading(false);
    if (signedIn) {
      const perms = auth.getPermissions();
      setPermissions(perms);
      if (perms === null) {
        try {
          await auth.updateAppAccessPermissions();
        } catch (error) {
          setError("Unable to fetch app access permissions.");
        }
      }
    }
  }

  // This is a workaround for sonar scan flagging the add/remove event listener
  // logic below as a bug: it doesn't like that checkAuthStatus is async and
  // returns a promise. This is a synchronous function that simply calls the
  // async function and can be used in the event listener.
  const callCheckAuthStatusSync = async () => {
    if (authMethod === COGNITO) {      
      checkAuthStatus();
    } else if (authMethod === KEYCLOAK) {
      const signedIn = await auth.userIsSignedIn();      
      setUserIsSignedIn(signedIn);
    }
  }

  const checkAuthPermissions = () => {
    setPermissions(auth.getPermissions() || null);
  }

  const authEventPending = () => {
    setUserIsSignedIn(false);
    setShowLoading(true);
  }

  useEffect(() => {
    if (authMethod === KEYCLOAK) {
      checkKeycloakStatus();
    } else if (authMethod === COGNITO) {      
      checkAuthStatus();
    }
    window.addEventListener(auth.events.AUTH_EVENT_PENDING, authEventPending);
    window.addEventListener(auth.events.AUTH_EVENT_OCCURRED, callCheckAuthStatusSync);
    window.addEventListener(auth.events.PERMISSIONS, checkAuthPermissions);
    return () => {
      window.removeEventListener(auth.events.AUTH_EVENT_PENDING, authEventPending);
      window.removeEventListener(auth.events.AUTH_EVENT_OCCURRED, callCheckAuthStatusSync);
      window.removeEventListener(auth.events.PERMISSIONS, checkAuthPermissions);
    }
  }, []);

  /**
   * Values accessible via the useAuth hook:
   * 
   * - userIsSignedIn (boolean)
   * - authToken (string | null)
   * - permissions (object | null)
   * - signIn (async (username, password) => null)
   * - signOut (async () => null)
   */
  const value = {
    userIsSignedIn,
    authToken,
    permissions,
    getAppPermissions: auth.getAppPermissions,
    getIdToken: auth.getIdToken,
    getUser,
    signOut,
  };

  const renderChildrenOrNotPermitted = () => {
    // If there's no checkPermissions function, don't check permissions.
    if (!checkPermissions) {
      return children;
    }

    if (error) {
      return (
        <div className="col">
          <div className="col">
            <Typography variant="heading3">
              Error
            </Typography>
            <Typography variant="heading5">
              {error}
            </Typography>
          </div>
        </div>
      )
    }

    // If there's a checkPermissions function but no permissions, show the
    // loading spinner, but not the app, while waiting for permissions.
    if (!permissions) {
      return (
        <div className="col">
          <CircularProgress />
        </div>
      )
    }

    const allowed = checkPermissions(permissions);
    return allowed
      ? children
      : <div className="col">
        <Typography variant="heading3">
          You do not have access to this page.
        </Typography>
      </div>
  }

  const redirectToSignIn = () => {
    if (authMethod === COGNITO) {
      return <SignIn />
    }
  }

  return (
    <AuthContextCtxtObj.Provider value={value}>
      {(showLoading && requireAuth) && <div className='col'><CircularProgress /></div>}
      {(!userIsSignedIn && !showLoading && requireAuth) && redirectToSignIn()}
      {(!requireAuth || (requireAuth && userIsSignedIn && !showLoading)) && renderChildrenOrNotPermitted()}
    </AuthContextCtxtObj.Provider>
  );
};

export default AuthContextProvider;
