import { useEffect } from "react";

type ResizableTextAreaType = {
    textAreaRef: HTMLTextAreaElement | null;
    value: string;
    lineHeight?: string;
    maxNumberOfLines?: number;
    minNumberOfLines?: number;
};

/**
 * Custom hook to modify a textarea to have a maximum height.
 * 
 * @param textAreaRef 
 * reference to the textarea
 * @param value 
 * current state value of the textarea
 * @param lineHeight (string)
 * specifies the height of a single line of text (specify px or em)
 * defaults to 3.5em
 * @param maxNumberOfLines (float)
 * maximum number of lines before the scrollbar is displayed
 * defaults to 3
 * @param minNumberOfLines (float)
 * minimum number of lines before the scrollbar is displayed
 * defaults to 1
 */
const useResizableTextarea = ({
    textAreaRef,
    value,
    lineHeight = "3.5em",
    maxNumberOfLines = 3,
    minNumberOfLines = 1,
}: ResizableTextAreaType) => {

    useEffect(() => {        
        if (textAreaRef) {
            const baseValue = parseFloat(
                window.getComputedStyle(document.body).fontSize
            ) || 16;
            textAreaRef.style.height = "0px";
            if (textAreaRef.scrollHeight / baseValue > maxNumberOfLines) {
                textAreaRef.style.height = `calc(${lineHeight}*${maxNumberOfLines})`;
                textAreaRef.style.overflowY = "auto";
            } else {
                textAreaRef.rows = minNumberOfLines;
            }
        }
    }, [textAreaRef, value]);
};

export default useResizableTextarea;
