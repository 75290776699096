import { redirectUserToAuthSite } from "@aiops/auth-util";
import useSavedPath from "../../hooks/useSavedPath";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import "./SignIn.scss";

/**
 *  Component that automatically redirects user to SSO prompt.
 */
const SignIn = () => {
  const { savePath } = useSavedPath();

  useEffect(() => {
    savePath();
    redirectUserToAuthSite();
  }, [])

  return (
    <div className="col">
      <CircularProgress />
    </div>
  );
};

export default SignIn;
