import "./Modal.scss";
import MuiModal from '@mui/material/Modal';

/**
 * Type for props of Modal component.
 */
type ModalProps = {
  open: boolean,
  onClose: () => void,
  children: React.ReactNode,
};

/**
 *
 */
const Modal = ({
  open,
  onClose,
  children,
}: ModalProps) => {

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: 'var(--modal-background)',
        backdropFilter: 'var(--modal-backdrop-filter)',
      }}
    >
      <div
        className="renewal-mgmt-modal"
      >
        {children}
      </div>
    </MuiModal>
  );
};

export default Modal;
