// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YQTItNev4qAjPyCuqORz2Q\\=\\={flex:1;display:flex;flex-direction:column}.YQTItNev4qAjPyCuqORz2Q\\=\\= div[class*=simple-tabpanel]{margin:8px 0 0 0;flex:1}.YQTItNev4qAjPyCuqORz2Q\\=\\= div[class*=simple-tabpanel] div[class*=panel-row]{margin-bottom:32px}.YQTItNev4qAjPyCuqORz2Q\\=\\= .h8Gz0NDnVcCyJ79dmGxv\\+A\\=\\={position:fixed;left:0;bottom:var(--platform-footer-height)}", "",{"version":3,"sources":["webpack://./src/components/Tabs/tabView.module.scss"],"names":[],"mappings":"AAAA,4BACE,MAAA,CACA,YAAA,CACA,qBAAA,CAEA,wDACE,gBAAA,CACA,MAAA,CACA,8EACE,kBAAA,CAIJ,yDACE,cAAA,CACA,MAAA,CACA,oCAAA","sourcesContent":[".tabsViewer {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n\n  div[class*='simple-tabpanel'] {\n    margin: 8px 0 0 0;\n    flex: 1;\n    div[class*=\"panel-row\"] {\n      margin-bottom: 32px;\n    }\n  }\n\n  .tabViewFooter {\n    position: fixed;\n    left: 0;\n    bottom: var(--platform-footer-height);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsViewer": "YQTItNev4qAjPyCuqORz2Q==",
	"tabViewFooter": "h8Gz0NDnVcCyJ79dmGxv+A=="
};
export default ___CSS_LOADER_EXPORT___;
