import descendingIcon from '../../assets/icons/arrowDescending.svg';
import ascendingIcon from '../../assets/icons/arrowAscending.svg';
import styles from '../Table/Table.module.scss';

/**
 * Type for props of TableHeaderCell component.
 */
export type TableHeaderCellProps = {
  // The title to display at the top of the column.
  title?: string,

  // True when this is the column by which the table is sorted.
  isCurrentSortColumn?: boolean,

  // True if the sort order if ascending. (If this column is not the current 
  // sort column this value is ignored/irrelevant.)
  sortedAscending?: boolean,

  // Function that runs when the cell is clicked. If this value is present the
  // cell will get the 'clickable' class; if undefined, it will not.
  onClick?: () => void,

  // Optional inline style object.
  styleObj?: Record<string, string>,
};

/**
 *
 */
const TableHeaderCell = ({
  title,
  sortedAscending = true,
  isCurrentSortColumn = false,
  onClick = undefined,
  styleObj = {},
}: TableHeaderCellProps) => {


  const getIcon = (isAscending: boolean) => {
    return (
      <img
        src={isAscending ? ascendingIcon : descendingIcon}
        alt={`in ${isAscending ? "ascending" : "descending"} order`}
      />
    )
  }

  const sortClass = isCurrentSortColumn ? styles["current-sort-column"] : "";
  const clickableClass = onClick !== undefined ? "clickable" : "";

  return (
    <th
      className={`${styles["aiops-table-header-cell"]} aiops-table-header-cell ${sortClass} ${clickableClass}`}
      style={styleObj}
      onClick={onClick}
      data-testid="table-header-cell"
    >
      <div className={styles["header-title"]}>
        {title}
      </div>
      {isCurrentSortColumn && getIcon(sortedAscending)}
    </th>
  );
};

export default TableHeaderCell;
