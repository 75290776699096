import {
  Autocomplete,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { FieldError } from 'react-hook-form';
import Checkbox from '../Checkbox';
import { DataProps } from './FormAutocomplete';

const defaultRenderOption = (selected, label) => {
  return (
    <div className='row'>
      <Checkbox
        style={{ marginRight: 8, }}
        checked={selected}
      />
      <Typography
        sx={{ color: 'var(--white)' }}
      >
        {label}
      </Typography>
    </div>
  );
};

type AutoCompleteProps = {
  displayName?: string;
  label: string;
  data: DataProps[];
  width: number;
  size: 'small' | 'medium';
  multiple: boolean;
  disableCloseOnSelect: boolean;
  renderOption?: (selected, label) => JSX.Element | JSX.Element[];
  field: any;
  onChange: (...event: any[]) => void;
  renderTag: (val: DataProps) => JSX.Element | JSX.Element[];
  value: any;
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
  error?: FieldError;
};

export const StyledAutocomplete = ({
  field,
  onChange,
  width,
  multiple,
  renderOption = defaultRenderOption,
  disableCloseOnSelect,
  label,
  data,
  size,
  renderTag,
  value,
  displayName,
  disabled,
  textfieldProps = {}, error
}: AutoCompleteProps) => {
  return (
    <div className='group'>
      {displayName &&
        <Typography
          variant='paragraph1-bold'
          className='label'
          data-testid='autocomplete-label'
        >
          {displayName}
        </Typography>
      }
      <Autocomplete
        {...field}
        value={value}
        onChange={(_event, values) => {
          onChange(values)
        }}
        sx={{
          width,
        }}
        className='autocomplete'
        PaperComponent={
          (props) => (
            <Paper
              variant='outlined'
              {...props}
              className='paper'
            />)
        }
        disabled={disabled}
        multiple={multiple}
        options={data}
        size={size}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={(option: DataProps) => option.label}
        renderTags={(val: DataProps) => renderTag(val)}
        renderOption={(props, option: { value: string, label: string, key?: string, id?: string }, { selected }) => (
          <li
            {...props}
            key={option.key || option.id || option.label}
          >
            {renderOption(selected, option.label)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            className='textfield'
            {...params}
            label={label}
            {...textfieldProps}
            error={!!error}
            helperText={error?.message}
          />
        )}
        isOptionEqualToValue={(option: DataProps, value: DataProps) => option.label === value.label}
      />
    </div>
  )
};