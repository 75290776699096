import TableRowCell from '../TableRowCell';
import Checkbox from '../Checkbox';
import { Selectability } from "../Table";
import styles from '../Table/Table.module.scss';

/**
 * Set of optional props for each TableRow.
 * 
 * @param rowIsClickable
 * Either a boolean, or a function that takes the row's row object as an input
 * and returns a boolean. If true, the row will display as clickable (the cursor
 * will turn into a pointer on hover).
 * 
 * @param onRowClick
 * Optional function that takes the row's row object as an input and will be run
 * whenever the row is clicked. **HOWEVER** if the row is selectable and the user
 * clicks on the checkbox, including in the case that the checkbox is disabled
 * and so unclickable, the onRowClick function will NOT be run. This is to prevent
 * the onRowClick function from running when the user is trying to click just
 * the checkbox (even if the checkbox is disabled/clicking it won't do anything).
 * 
 * @param cellStyles
 * Optional object where each key is a columnKey and the value is an inline
 * style object to apply to the cell in the column with that key.
 * 
 * @param cellStyleGenerators
 * Optional object where each key is a columnKey and the value is a function
 * that takes the row's row object as an input and returns an inline style
 * object to apply to the cell in the column with that key.
 * 
 * @param cellClassNames
 * Optional object where each key is a columnKey and the value is a string that
 * is a whitespace-separated list of class names to apply to the cell in that
 * column.
 * 
 * @param cellClassNameGenerators
 * Optional object where each key is a columnKey and the value is a function
 * that takes the row's row object as an input and returns a single string that
 * is a whitespace-separated list of class names to apply to the cell in that
 * column.
 * 
 * @param cellOnClickFunctions
 * Optional object where each key is a columnKey and the value is a function 
 * that the cell is passed to run if the cell is clicked.
 * 
 */
export type OptionalTableRowProps = {
  rowIsClickable?: boolean | ((row: Record<string, any>) => boolean),
  onRowClick?: (row: Record<string, any>) => void,
  cellStyles?: Record<string, Record<string, React.CSSProperties>>,
  cellStyleGenerators?: Record<string, (row: Record<string, any>) => Record<string, React.CSSProperties>>,
  cellClassNames?: Record<string, string>,
  cellClassNameGenerators?: Record<string, (row: Record<string, any>) => string>,
  cellOnClickFunctions?: Record<string, (row: Record<string, any>, rowIndex?: number) => any>,
}

/**
 * Type for props of TableRow component.
 */
type TableRowProps = {
  columnKeys: Array<string | number>,
  row: Record<string, any>,
  rowIndex: number,
  rowProps?: OptionalTableRowProps,
  selectability?: Selectability,
};

/**
 *  Renders a row in a Table component.
 */
const TableRow = ({
  row,
  rowIndex,
  columnKeys,
  rowProps,
  selectability = undefined,
}: TableRowProps) => {

  // Defaults to use for optional props in the case that they aren't passed.
  const defaultOptionalProps: OptionalTableRowProps = {
    rowIsClickable: false,
    onRowClick: undefined,
    cellStyles: {},
    cellStyleGenerators: {},
    cellClassNames: {},
    cellClassNameGenerators: {},
    cellOnClickFunctions: {},
  };

  // Get optional row props, preferring the prop that was passed if one was
  // indeed passed, falling back to the default if not.
  const {
    rowIsClickable,
    onRowClick,
    cellStyles,
    cellStyleGenerators,
    cellClassNames,
    cellClassNameGenerators,
    cellOnClickFunctions,
  } = {
    ...defaultOptionalProps,
    ...rowProps,
  }

  // Get selectability props if they were passed, or undefined if not.
  const {
    rowIsSelected,
    rowIsSelectable,
    onSelectRowToggle,
  } = selectability || {};

  // This classname is set as a constant because it's used to check whether the
  // user was clicking on a disabled checkbox in the clickedOnCheckbox function.
  const CHECKBOX_WRAP_CLASSNAME = "aiops-table-row-checkbox";

  const checkboxCell = () => {
    return (
      <td
        data-testid="checkbox-cell"
        className={`${styles[CHECKBOX_WRAP_CLASSNAME]} ${CHECKBOX_WRAP_CLASSNAME} col`}
        key={`checkbox-key`}
      >
        <Checkbox
          disabled={
            typeof rowIsSelectable === 'boolean'
              ? !rowIsSelectable
              : !rowIsSelectable(row)
          }
          onChange={(e) => onSelectRowToggle(e.target.checked, row)}
          checked={rowIsSelected(row)}
        />
      </td >
    )
  }

  // Returns true if the event's target was a checkbox (whether disabled or not).
  const clickedOnCheckbox = (event: any) => {
    return event.target.type === "checkbox"
      || event.target.className?.includes(CHECKBOX_WRAP_CLASSNAME);
  }

  // True if the row should be clickable; false otherwise.
  const appearClickable = typeof rowIsClickable === 'boolean'
    ? rowIsClickable
    : rowIsClickable(row);

  // True if the row should be clickable or selectable; false otherwise.
  const appearSelectable = typeof rowIsSelectable === 'boolean'
    ? rowIsSelectable
    : false;

  // True is checkbox is present () or the whole row is clickable
  const isRowSelectableOrClickable = appearSelectable || appearClickable;
  const rowSelectableorClickableClass = isRowSelectableOrClickable ? styles.clickable : "";

  return (
    <tr
      data-testid="table-row"
      className={`${rowSelectableorClickableClass} ${styles["aiops-table-row"]} aiops-table-row row`}
      onClick={(event: any) => {
        if (!clickedOnCheckbox(event) && onRowClick) {
          onRowClick(row);
        }
      }}
    >
      {selectability && checkboxCell()}
      {columnKeys.map((key, idx) => {
        // Generate a conditional inline style object for each cell (column) 
        // where that function was provided.
        const conditionalStyles = cellStyleGenerators[key]
          ? cellStyleGenerators[key](row)
          : {};

        // Overwrite cell styles with conditional styles, if both are present.
        const style = {
          ...cellStyles[key],
          ...conditionalStyles,
        }

        // Generate conditional class names for each cell (column) where that
        // function was provided.
        const conditionalClassNames = cellClassNameGenerators[key]
          ? cellClassNameGenerators[key](row)
          : "";

        // Join cellClassNames, if any, with conditionally generated cell class
        // names, if that function is present, to create a single string of
        // class names.
        const classNames = [cellClassNames[key] || "", conditionalClassNames]
          .filter((str) => str)
          .join(" ");

        const onCellClick = cellOnClickFunctions[key] || (() => undefined);

        return (
          <TableRowCell
            key={idx}
            data={row[key]}
            // Only pass a style object if it's not empty.
            style={Object.keys(style).length > 0 ? style : undefined}
            classNames={classNames}
            onClick={() => onCellClick(row, rowIndex)}
          />
        )
      })}
    </tr>
  );
};

export default TableRow;
